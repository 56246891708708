import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { api } from '../../configs/axios';
import { tableBodyPeriodeAktif } from '../Table/tablePeriodeAktif';
import { tableBodyPeriodeTidakAktif } from '../Table/tablePeriodeTidakAktif';
import {
    defaultLimit,
    toastMessageCall,
} from '../../helpers/Utils';

import 'react-toastify/dist/ReactToastify.css';

const Periode = () => {
    const [dataTablePeriodeAktif, setDataTablePeriodeAktif] = useState([]);
    const [dataTablePeriodeTidakAktif, setDataTablePeriodeTidakAktif] = useState([])
    const [loading, setLoading] = useState(false);
    const [isModal, setModal] = useState('');

    /** variable */
    const [xStatusAktif, setxStatusAktif] = useState();
    const [xIdPeriode, setxIdPeriode] = useState('');
    const [xPeriode, setxPeriode] = useState('');
    const [xTahun, setxTahun] = useState('');
    const [key, setKey] = useState();

    /** Paging */
    const [pnextPage, setpnextPage] = useState(0);
    const [pDisplay, setpDisplay] = useState('none');

    const loadDataTablePeriodeAktif = () => {
        var param = { key: true + "~$~" }
        api.get('/periode', { params: param }).then(res => {
            if (res.data.result) {
                var ar = [];
                if (res.data.data.length) {
                    res.data.data.map((item) => {
                        ar.push(JSON.parse(item));
                    });
                };
                setDataTablePeriodeAktif(ar);
            }
        });
    };

    const loadDataTablePeriodeTidakAktif = (page) => {
        setLoading(true)
        //console.log(page);
        var param = { key: 'False' + "~$~" + xIdPeriode, limit: defaultLimit(), start_row: page };
        api.get('/periode', { params: param }).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.message, 'top-center', 'warning');
                return false;
            };

            if (res.data.data.length < 1 && page == 0) {
                setpDisplay('none');
                return false;
            };

            var ar = dataTablePeriodeTidakAktif
            if (page == 0) {
                ar = [];
            };

            if (res.data.jumlah_data > ar.length) {
                if (res.data.data.length) {
                    res.data.data.map((item, i, row) => {
                        if (i + 1 == (row.length)) {
                            let last_item = JSON.parse(item[0]);
                            setpnextPage(last_item.id_periode);
                        };
                        ar.push(JSON.parse(item));
                    });
                    setDataTablePeriodeTidakAktif(ar);
                    setpDisplay('block')
                };
            } else {
                setpDisplay('none');
            };
        }).catch(err => {
            toastMessageCall('Gagal terhubungan dengan backend. Error : ' + err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const kosongBox = () => {
        setxStatusAktif('');
        setxIdPeriode('');
        setxTahun('');
        setxPeriode('');
        setKey('');
    };

    const loadData = (item) => {
        //console.log(item.status_aktif.toString());
        //var status = item.status_aktif.toString().charAt(0).toUpperCase() + item.status_aktif.toString().slice(1);
        //console.log(status);
        setKey(item.status_aktif + '~$~' + item.id_periode);
        setxStatusAktif(item.status_aktif);
        setxIdPeriode(item.id_periode);
        setxPeriode(item.periode);
        setxTahun(item.tahun);
    }

    const simpanPeriode = () => {
        if (!xTahun) {
            toastMessageCall('Tahun tidak boleh kosong', 'top-center', 'warning');
            return false;
        };

        if (!xPeriode) {
            toastMessageCall('Periode tidak boleh kosong', 'top-center', 'warning');
            return false;
        };

        setLoading(true);
        api.post('/periode', {
            key: key,
            periode: xPeriode,
            tahun: xTahun
        }).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.message, 'top-center', 'warning');
                return false;
            };
            toastMessageCall(res.data.message, 'top-center', 'success');
            loadDataTablePeriodeAktif(0);
            loadDataTablePeriodeTidakAktif(0);
            kosongBox();
            setModal('');
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    };

    const deletePeriode = () => {
        if (!key) {
            toastMessageCall('Belum ada data yang dipilih', 'top-center', 'warning');
            return false;
        };

        setLoading(true);
        api.delete('/periode/' + key).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.message, 'top-center', 'error');
                return false;
            };
            toastMessageCall(res.data.message, 'top-center', 'success');
            loadDataTablePeriodeAktif(0);
            loadDataTablePeriodeTidakAktif(0);
            setModal('');
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const setAktif = (item) => {
        if (item) {
            api.post('/periode/aktif', {
                key: item.status_aktif + '~$~' + item.id_periode,
                periode: item.periode,
                tahun: item.tahun
            }).then(res => {
                if (!res.data.result) {
                    toastMessageCall(res.data.message, 'top-center', 'error');
                    return false;
                };
                toastMessageCall(res.data.message, 'top-center', 'success');
                loadDataTablePeriodeAktif(0);
                loadDataTablePeriodeTidakAktif(0);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const nextPage = () => {
        loadDataTablePeriodeTidakAktif(pnextPage);
    };

    useEffect(() => {
        loadDataTablePeriodeAktif(0);
        loadDataTablePeriodeTidakAktif(0);
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Periode</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">KPI</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Periode</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <button type='button' className='btn btn-sm btn-primary mr-1 mb-2' onClick={() => {
                                kosongBox();
                                setModal('modal-periode');
                            }}>Tambah Periode</button>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-custom spacing8">
                                            <thead>
                                                <tr>
                                                    <th>TAHUN</th>
                                                    <th>PERIODE</th>
                                                    <th>STATUS</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableBodyPeriodeAktif(loading, dataTablePeriodeAktif, { loadData }, { setModal })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className='table-responsive'>
                            <table className="table table-hover table-custom spacing8">
                                <tbody>
                                    {tableBodyPeriodeTidakAktif(loading, dataTablePeriodeTidakAktif, { loadData }, { setModal }, { setAktif })}
                                    <tr>
                                        <td colSpan={5} style={{ textAlign: 'center' }}><NavLink to="#" className="text-success" onClick={() => { nextPage(); }} style={{ display: pDisplay }}><i className="fa fa-refresh fa-spin"></i> Tampilkan lebih banyak</NavLink></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal fade ${isModal === 'modal-periode' ? 'd-block show' : ''}`}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Periode</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ width: '150px' }}>Tahun</span>
                                </div>
                                <input type="text" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" value={xTahun} onChange={(e) => { setxTahun(e.target.value.toUpperCase()) }} />
                            </div>

                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ width: '150px' }}>Periode</span>
                                </div>
                                <input type="text" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" value={xPeriode} onChange={(e) => { setxPeriode(e.target.value.toUpperCase()) }} />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => {
                                setModal('');
                                kosongBox();
                            }}>Tutup</button>
                            <button type="button" className="btn btn-round btn-primary" onClick={() => {
                                simpanPeriode();
                            }} >Simpan</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal fade ${isModal === 'modal-hapus-periode' ? 'd-block show' : ''}`} >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Periode</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className='modal-body text-center pt-4 mb-4'>
                            <h5>Yakin data akan dihapus?</h5>
                            <p className="mb-4">Data yang sudah dihapus tidak bisa dikembalikan lagi.</p>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => {
                                setModal('');
                                kosongBox();
                            }}>Tutup</button>
                            <button type="button" className="btn btn-round btn-primary" onClick={() => {
                                deletePeriode();
                            }}>Hapus</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Periode; 