import React, { useEffect, useState, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Tooltip from "../common/toolTip";
import { api, apiWs } from "../../configs/axios";
import { defaultLimit, toastMessageCall } from "../../helpers/Utils";

import { comboKantor } from "../Combo/ComboKantor";
import { comboJabatan } from "../Combo/ComboJabatan";
import { tableBodyPegawaiAktif } from "../Table/tablePegawaiAktif";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import "react-toastify/dist/ReactToastify.css";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.min.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import swal from "sweetalert";
import Loader from "../Components/Loader";

import ReactExport from "react-export-excel-hot-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const roleList = [
  { roleValue: "admin", roleDisplay: "Admin" },
  { roleValue: "direksi", roleDisplay: "Direksi" },
  { roleValue: "atasan", roleDisplay: "Atasan" },
  { roleValue: "pegawai", roleDisplay: "Pegawai" },
];

const Pegawai = () => {
  const [dataTablePegawaiAktif, setDataTablePegawaiAktif] = useState([]);
  const [dataComboKantor, setDataComboKantor] = useState([]);
  const [dataComboJabatan, setDataComboJabatan] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isModal, setModal] = useState("");

  const [xId, setxId] = useState("");
  const [xNpp, setxNpp] = useState("");
  const [xNama, setxNama] = useState("");
  const [xKantor, setxKantor] = useState("");
  const [xJabatan, setxJabatan] = useState("");
  const [xStatus, setxStatus] = useState("");
  const [xFoto, setxFoto] = useState("-");
  const [xRoles, setxRoles] = useState({ roleValue: "", roleDisplay: "" });
  const [key, setKey] = useState("");

  /** Paging */
  const [pnextPage, setpnextPage] = useState(0);
  const [pDisplay, setpDisplay] = useState("none");

  const [tableHeader, setTableHeader] = useState([
    { field: "npp", headerName: "NPP" },
    { field: "nama", headerName: "Nama Pegawai" },
    { field: "jabatan", headerName: "Jabatan" },
    { field: "kantor", headerName: "Kantor" },
    {
      field: "id_pegawai",
      headerName: "Aksi",
      cellRendererFramework: (params) => (
        <div>
          {/* <Tooltip text="Set Tidak Aktif" id="SetTidakAktif" />
                <button type="button" className="btn btn-sm btn-default" title="Set tidak Aktif" data-for="setTidakAktif" onClick={() => {
                    //setAktif(key);
                }}><i className="icon-close"></i></button>{" "} */}
          <Tooltip text="Edit" id="Edit" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Edit"
            data-for="edit"
            data-tip
            onClick={() => {
              loadData(params.data);
              setModal("modal-pegawai");
            }}
          >
            <i className="icon-pencil"></i>
          </button>{" "}
          <Tooltip text="Hapus" id="Hapus" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Hapus"
            data-for="hapus"
            data-tip
            onClick={() => {
              loadData(params.data);
              setModal("modal-hapus-pegawai");
            }}
          >
            <i className="icon-trash"></i>
          </button>
          <Tooltip text="Reset Password" id="Reset" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Reset Password"
            data-for="reset"
            data-tip
            onClick={() => {
              resetPassword(params.data.id_pegawai);
            }}
          >
            <i className="fa fa-refresh"></i>
          </button>
        </div>
      ),
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  }));

  const kosongBox = () => {
    setxId("");
    setxNpp("");
    setxNama("");
    setxKantor("");
    setxJabatan("");
    setxFoto("-");
    setxStatus("");
    setxRoles("");
    setKey("");
  };

  const loadData = (item) => {
    if (item) {
      setxId(item.id_pegawai);
      setxNpp(item.npp);
      setxNama(item.nama);
      setxKantor(item.kantor);
      setxJabatan(item.jabatan);
      setxRoles({
        roleValue: item.roles,
        roleDisplay: roleList.filter((role) => role.roleValue === item.roles)[0]
          .roleDisplay,
      });
      setxStatus(item.status_aktif);
      setKey(
        item.status_aktif +
        "~$~" +
        item.kantor +
        "~$~" +
        item.jabatan +
        "~$~" +
        item.npp +
        "~$~" +
        item.id_pegawai
      );
    }
  };

  const loadDataComboKantor = () => {
    api.get("/kantor").then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            const kantor = JSON.parse(item);
            ar.push(kantor.kantor);
          });
        }
        setDataComboKantor(ar);
      }
    });
  };

  const loadDataComboJabatan = () => {
    var param = { key: "~$~2" };
    api.get("/jabatan", { params: param }).then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            const jabatan = JSON.parse(item);
            ar.push(jabatan.jabatan);
          });
        }
        setDataComboJabatan(ar);
      }
    });
  };

  const loadDataTableAktif = (page) => {
    setLoading(true);
    var param = {
      key: "true~$~~$~~$~~$~",
      limit: defaultLimit(),
      start_row: page,
    };
    api
      .get("/pegawai", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item, i, row) => {
            ar.push(JSON.parse(item[0]));
          });
        }

        setDataTablePegawaiAktif(ar);

        // if (res.data.data.length < 1 && page == 0) {
        //     setpDisplay('none');
        //     setDataTablePegawaiAktif([]);
        //     return false;
        // };

        // var ar = dataTablePegawaiAktif;
        // if (page == 0) {
        //     ar = [];
        // };

        // if (res.data.jumlah_data > ar.length) {
        //     if (res.data.data.length) {
        //         res.data.data.map((item, i, row) => {
        //             if (i + 1 == (row.length)) {
        //                 let last_item = JSON.parse(item[0]);
        //                 setpnextPage(last_item.row_no);
        //             }
        //         });
        //     }
        // }
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetPassword = (id) => {
    swal({
      title: "Apakah anda yakin reset password pegawai ini?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiWs
          .post(`/reset-pass/${id}`)
          .then((res) => {
            toastMessageCall(
              "Password berhasil direset",
              "top-center",
              "success"
            );
            loadDataTableAktif();
          })
          .catch((err) => {
            toastMessageCall("Password gagal direset", "top-center", "error");
          });
      } else {
        toastMessageCall("Password gagal direset", "top-center", "error");
      }
    });
  };

  const simpanPegawai = () => {
    if (!xNpp) {
      toastMessageCall("NPP tidak boleh kosong.", "top-center", "warning");
      return false;
    }

    if (!xNama) {
      toastMessageCall("Nama tidak boleh kosong.", "top-center", "warning");
      return false;
    }

    if (!xKantor) {
      toastMessageCall("Kantor tidak boleh kosong.", "top-center", "warning");
      return false;
    }

    if (!xJabatan) {
      toastMessageCall("Jabatan tidak boleh kosong.", "top-center", "warning");
      return false;
    }

    setLoading(true);
    api
      .post("/pegawai", {
        key: key,
        npp: xNpp,
        nama: xNama,
        kantor: xKantor,
        jabatan: xJabatan,
        status: xStatus,
        roles: xRoles.roleValue,
      })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "error");
          return false;
        }

        toastMessageCall(res.data.message, "top-center", "success");
        loadDataTableAktif(0);
        kosongBox();
        setModal("");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletePegawai = () => {
    if (!key) {
      toastMessageCall("Belum ada data yang dipilih", "top-center", "warning");
      setModal("");
      return false;
    }

    setLoading(true);
    api
      .delete("/pegawai/" + key)
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "error");
          return false;
        }
        toastMessageCall(res.data.message, "top-center", "success");
        loadDataTableAktif(0);
        setModal("");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDataTableAktif(0);
    loadDataComboKantor();
    loadDataComboJabatan();
  }, []);

  function ExcelExport({ data }) {
    return (
      <ExcelFile filename={`Data Pegawai`} element={
        <button type="button" className="btn btn-sm btn-success btn-block mr-1">
          Export Data Pegawai ke Excel
        </button>}>
        <ExcelSheet data={data} name="Pegawai">
          <ExcelColumn label="NPP" value="npp" />
          <ExcelColumn label="Nama Pegawai" value="nama" />
          <ExcelColumn label="Jabatan" value="jabatan" />
          <ExcelColumn label="Kantor" value="kantor" />
          <ExcelColumn label="Role" value="roles" />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  // useEffect(() => {
  //     console.log(key);
  // }, [key])

  return (
    <>
      {loading ? <Loader /> : (
        <>
          <ToastContainer />
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-md-8 col-sm-12">
                  <h2>Pegawai</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Worksheet</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Pegawai
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-md-2 col-sm-12 text-right">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary btn-block mr-1"
                    onClick={() => {
                      //kosongBox();
                      setModal("modal-pegawai");
                    }}
                  >
                    Tambah Pegawai
                  </button>
                </div>
                <div className="col-md-2 col-sm-12 text-right">
                  <ExcelExport data={dataTablePegawaiAktif} />
                </div>
              </div>

              {/* <div className="row clearfix">
                        <div className="col-12">
                            <div className="card my-3">
                                <div className="body b-cyan">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Kantor" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="NPP" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Nama Pegawai" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <a href="#" className="btn btn-sm btn-primary btn-block" title="">Cari</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>
            <div className="row clearfix">
              {/* <div className="col-lg-3 col-md-4 col-sm-12">
                        <small className="text-muted">SKILL</small>
                        <ul className="list-group mt-2 mb-4">
                            <li className="list-group-item d-flex justify-content-between align-items-center">Semua Skill<span className="badge badge-info">14</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Computer<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Marketing<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Design<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Writing<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Data Analytic<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Communication<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Problem Solving<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Interpersonal<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Leadership<span className="badge badge-info">1</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Adaptive<span className="badge badge-info">1</span></li>
                        </ul>
                        <small className="text-muted">KANTOR</small>
                        <ul className="list-group mt-2 mb-4">
                            <li className="list-group-item d-flex justify-content-between align-items-center">Semua Kantor<span className="badge badge-info">14</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Kantor A<span className="badge badge-primary">2</span></li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">Kantor B<span className="badge badge-primary">2</span></li>
                        </ul>

                    </div> */}
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row clearfix">
                  <div className="col-lg-12">
                    <div className="card">
                      {/* <ul className="nav nav-tabs">
                                        <li className="nav-item"><a className="nav-link active show" data-toggle="tab" href="#aktif">Aktif</a></li>
                                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tidak-aktif">Tidak Aktif</a></li>
                                    </ul> */}
                      <div className="tab-content mt-0">
                        <div className="tab-pane active show" id="aktif">
                          {/* <div className="table-responsive">
                                                <table className="table table-hover table-custom spacing8">
                                                    <thead>
                                                        <tr>
                                                            <th className='w60'>NAMA</th>
                                                            <th></th>
                                                            <th>KANTOR</th>
                                                            <th className='w100'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableBodyPegawaiAktif(loading, dataTablePegawaiAktif, { loadData }, { setModal })}
                                                        <tr>
                                                            <td colSpan={4} style={{ textAlign: 'center' }}><NavLink to="#" className="text-success" onClick={() => { nextPage(); }} style={{ display: pDisplay }}><i className="fa fa-refresh fa-spin"></i> Tampilkan lebih banyak</NavLink></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}

                          <div
                            className="ag-theme-alpine"
                            style={{ width: "100%", height: 700 }}
                          >
                            <AgGridReact
                              rowData={dataTablePegawaiAktif} // Row Data for Rows
                              columnDefs={tableHeader} // Column Defs for Columns
                              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                              defaultColDef={defaultColDef}
                              pagination={true}
                              paginationPageSize={20}
                            />
                          </div>
                        </div>
                        <div className="tab-pane" id="tidak-aktif"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`modal fade ${isModal === "modal-pegawai" ? "d-block show" : ""
              }`}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Pegawai</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("")
                      kosongBox()
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" style={{ width: "150px" }}>
                        NPP
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      aria-label="npp"
                      aria-describedby="basic-addon1"
                      value={xNpp}
                      onChange={(e) => {
                        setxNpp(e.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" style={{ width: "150px" }}>
                        Nama
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="nama"
                      aria-describedby="basic-addon1"
                      value={xNama}
                      onChange={(e) => {
                        setxNama(e.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group mb-3">
                    {/* <ThemeProvider theme={darkTheme}> */}
                    <Autocomplete
                      id="combo-box-demo"
                      size="small"
                      options={dataComboKantor}
                      autoHighlight
                      value={xKantor || null}
                      onChange={(e, v) => {
                        setxKantor(v);
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Kantor"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {/* </ThemeProvider> */}
                  </div>
                  <div className="input-group mb-3">
                    {/* <ThemeProvider theme={darkTheme}> */}
                    <Autocomplete
                      id="combo-box-demo"
                      size="small"
                      options={dataComboJabatan}
                      autoHighlight
                      value={xJabatan || null}
                      onChange={(e, v) => {
                        setxJabatan(v);
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Jabatan"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {/* </ThemeProvider> */}
                  </div>
                  <div className="input-group mb-3">
                    {/* <ThemeProvider theme={darkTheme}> */}
                    <Autocomplete
                      id="combo-box-demo"
                      size="small"
                      options={roleList}
                      autoHighlight
                      value={xRoles || null}
                      getOptionLabel={(option) => option.roleDisplay}
                      isOptionEqualToValue={(option, value) =>
                        option.roleValue === value.roleValue
                      }
                      onChange={(e, v) => {
                        setxRoles(v);
                      }}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Role"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {/* </ThemeProvider> */}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-round btn-default"
                    data-dismiss="modal"
                    onClick={() => {
                      setModal("");
                      kosongBox();
                    }}
                  >
                    Tutup
                  </button>
                  <button
                    type="button"
                    className="btn btn-round btn-primary"
                    onClick={() => {
                      simpanPegawai();
                    }}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`modal fade ${isModal === "modal-hapus-pegawai" ? "d-block show" : ""
              }`}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Pegawai</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("")
                      kosongBox()
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body text-center pt-4 mb-4">
                  <h5>Yakin data akan dihapus?</h5>
                  <p className="mb-4">
                    Data yang sudah dihapus tidak bisa dikembalikan lagi.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-round btn-default"
                    data-dismiss="modal"
                    onClick={() => {
                      setModal("");
                      kosongBox();
                    }}
                  >
                    Tutup
                  </button>
                  <button
                    type="button"
                    className="btn btn-round btn-primary"
                    onClick={() => {
                      deletePegawai();
                    }}
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Pegawai;
