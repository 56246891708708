import React from "react";

const comboLevel = (ar) =>{
	return ar && ar.map((key, index)=>{
		return(
			<option key={"option_level_"+index} value={key.level}>{key.level}</option>
		)
	})
};

export{
    comboLevel
};