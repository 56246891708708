import LogoImage from "../../assets/images/Logo-bankNTT1.svg"
import LoaderImage from "../../assets/images/loader.gif"

export default function Loader() {
    return (
        <div
            style={{
                display: "flex",
                height: "90vh",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img src={LogoImage} alt="Loader Logo" height={200}/>
            <img src={LoaderImage} alt="Loader Animation" height={100}/>
        </div>
    )
}