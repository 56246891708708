import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import { api, apiWs } from '../../configs/axios';
import {
    defaultLimit,
    toastMessageCall,
    encrypt
} from '../../helpers/Utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import swal from 'sweetalert';
import { useDropzone } from 'react-dropzone';
import formatBytes from '../../helpers/Utils'
import { TabelWorksheet, JenisHandler, StatusHandler, FormatDate } from './TabelWorksheet';
import { getCookie } from '../../helpers/CookieManager';
import Loader from "../Components/Loader";
import Table from 'react-bootstrap/Table';
import { BtnSelesaiByAdmin } from '../Components/SelesaiByAdmin';

const DetailProjectList = () => {
    const jabatanAtasanTop = [
        'Kepala Divisi',
        'Pemimpin Cabang Utama',
        'Pemimpin Cabang',
        'Pemimpin Cabang Khusus'
    ];

    // const token = window.sessionStorage.getItem('token')
    const token = getCookie('token')
    const tokenBody = token.split('.')[1];
    const result = JSON.parse(atob(tokenBody))

    const [sessionCred, setSessionCred] = useState(result.sub)
    const [kantorIndukTugas, setKantorIndukTugas] = useState()
    const [nppPegawai, setNppPegawai] = useState([])
    const [nppAtasan, setNppAtasan] = useState([])

    const [isModal, setModal] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [dSkill, setDskill] = useState([])
    const [status, setStatus] = useState('')
    const [nama, setNama] = useState([])
    const [file, setFile] = useState([])
    const [kesalahanPegawai, setKesalahanPegawai] = useState([])
    const [history, setHistory] = useState([])
    const [skill, setSkill] = useState([])
    const [kesalahan, setKesalahan] = useState([])
    const [jenis, setJenis] = useState('')
    const [judul, setJudul] = useState('')
    const [atasan, setAtasan] = useState('')
    const [tugas, setTugas] = useState('')
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const [exp, setExp] = useState('')
    const [nominal, setNominal] = useState('')
    const [jmlAtasan, setJmlAtasan] = useState('')
    const [jmlKoreksi, setJmlKoreksi] = useState(0)
    const [nilai, setNilai] = useState('')
    const [triwulan, setTriwulan] = useState('')
    const [triwulanTahun, setTriwulanTahun] = useState('')
    const [submiting, setSubmiting] = useState(false)

    const [xket, setXket] = useState('')
    const [xskill, setXskill] = useState([])
    const [xkesalahan, setXkesalahan] = useState('')
    const [xFile, setXfile] = useState('')

    const xid = useParams()
    const id = xid.id

    const [kesalahanChanged, setKesalahanChanged] = useState(false)

    const lampiranTugasImageRef = useRef(null)
    const lampiranTugasPdfRef = useRef(null)
    const [lampiranType, setLampiranType] = useState(null)

    const onDrop = useCallback(async f => {
        try {
            setXfile(f)
            // console.log(f)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const onDropRejected = useCallback(async f => {
        const errorCode = f[0].errors[0].code;
        let errorMessage = "";
        switch (errorCode) {
            case "too-many-files":
                errorMessage = "Jumlah berkas tidak sesuai ketentuan!"
                break;
            case "file-too-large":
                errorMessage = "Ukuran berkas tidak sesuai ketentuan!"
                break;
            default:
                errorMessage = "Berkas tidak sesuai ketentuan!"
                break;
        }
        toastMessageCall(errorMessage, 'top-center', 'warning');
    }, [])

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        // accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [] },
        onDrop,
        onDropRejected,
        maxFiles: 10,
        maxSize: (1000000 * 20),
    })

    const getData = () => {
        setIsLoading(true)
        apiWs.get(`/tugas/${id}`).then(res => {
            var ar = [];
            if (res.data.data.length) {
                res.data.data.map((item) => {
                    ar.push(JSON.parse(item))
                });
                setNppAtasan([ar[0].npp_atasan])
                setJenis(ar[0].jenis)
                setJudul(ar[0].judul)
                setTugas(ar[0].tugas)
                setStart(ar[0].start)
                setEnd(ar[0].end)
                setExp(ar[0].exp)
                setAtasan(ar[0].nama_atasan)
                setStatus(ar[0].status)
                setNominal(ar[0].nominal)
                if (ar[0].jml_atasan === null) {
                    setJmlAtasan(ar[0].nominal)
                }
                else {
                    setJmlAtasan(ar[0].jml_atasan)
                }
                if (ar[0].jml_koreksi === null) {
                    setJmlKoreksi(0)
                }
                else {
                    setJmlKoreksi(ar[0].jml_koreksi)
                }
                setNilai(ar[0].nilai)
                setTriwulan(ar[0].triwulan)
                setTriwulanTahun(ar[0].triwulan_tahun)
            };

            var br = [];
            let nppPegawais = []
            if (res.data.pegawai.length) {
                res.data.pegawai.map((item) => {
                    br.push(item)
                    nppPegawais.push(item.npp)
                });
                setNppPegawai(nppPegawais)
                setNama(br)
            };

            var cr = [];
            if (res.data.skill.length) {
                res.data.skill.map((item) => {
                    cr.push(JSON.parse(item))
                });
                setDskill(cr)
            };

            var dr = [];
            if (res.data.history.length) {
                res.data.history.map((item) => {
                    dr.push(JSON.parse(item))
                });

                dr.sort(function (x, y) {
                    return new Date(x.cts) < new Date(y.cts) ? 1 : -1
                })
                setHistory(dr)
            };

            var er = [];
            if (res.data.berkas.length) {
                res.data.berkas.map((item) => {
                    er.push(JSON.parse(item))
                });
                setFile(er)
            };

            var fr = [];
            if (res.data.kesalahanPegawai.length) {
                res.data.kesalahanPegawai.map((item) => {
                    fr.push(JSON.parse(item))
                });
                setKesalahanPegawai(fr)
            };
            setIsLoading(false)
        })

        apiWs.get(`/skill`).then(res => {
            var ar = [];
            if (res.data.data.length) {
                res.data.data.forEach((item) => {
                    ar.push(item);
                });
                setSkill(ar)
            };
        })
            
        apiWs.get(`/kesalahan`).then(res => {
            var ar = [];
            if (res.data.data.length) {
                res.data.data.forEach((item) => {
                    ar.push(item);
                });
                setKesalahan(ar)
            };
        })
    }

const handleChange = (e) => {
    const name = e.target.name
    let value = Array.from(e.target.selectedOptions, option => option.value);
    if (name === "skill") {
        if (value.includes('')) {
            setXskill('')
        } else {
            setXskill(value)
        }
    }
    else if (name === "kesalahan") {
        if (value.includes('')) {
            setXkesalahan('')
        }
        else {
            setXkesalahan(value)
        }
    }
}

const download = (filename) => {
    const param = {
        filename
    }
    apiWs.get(`/download/${id}`, {
        params: param,
        responseType: 'blob'
    })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
}

const preview = (filename, type = 'dikerjakan') => {
    const param = {
        filename
    }
    if (type === 'revisi') {
        apiWs.get(`/downloads/${id}`, {
            params: param,
            responseType: 'blob'
        }).then(res => previewProcess(filename, res))
    } else if (type === 'penugasan') {
        apiWs.get(`/downloads_penugasan/${id}`, {
            params: param,
            responseType: 'blob'
        }).then(res => previewProcess(filename, res))
    }
    else {
        apiWs.get(`/download/${id}`, {
            params: param,
            responseType: 'blob'
        }).then(res => previewProcess(filename, res))
    }
}

const previewProcess = (filename, res) => {
    // IF FILE IS PDF
    if (filename.endsWith(`.pdf`)) {
        setLampiranType('pdf')
        setModal('Modal Preview')
        const element = lampiranTugasPdfRef.current
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        element.src = url
    }
    // IF FILE NOT PDF (IMAGE)
    else {
        setLampiranType('image')
        setModal('Modal Preview')
        const element = lampiranTugasImageRef.current
        const url = window.URL.createObjectURL(new Blob([res.data]));
        element.src = url
    }
}

const downloadRevisi = (filename) => {
    const param = {
        filename
    }
    apiWs.get(`/downloads/${id}`, {
        params: param,
        responseType: 'blob'
    })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
}

const downloadPenugasan = (filename) => {
    const param = {
        filename
    }
    apiWs.get(`/downloads_penugasan/${id}`, {
        params: param,
        responseType: 'blob'
    })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
}

const approveTerima = (id) => {
    swal({
        title: "Apakah anda yakin menerima tugas ini?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete) {
                // console.log(sessionCred);
                // return false
                setSubmiting(true)
                apiWs.post(`/approve/${id}`, { approvedBy: sessionCred.nama })
                    .then(res => {
                        toastMessageCall('Tugas berhasil diterima', 'top-center', 'success');
                        window.location = '/app-project-list'
                    })
                    .catch(err => {
                        toastMessageCall('Tugas gagal diterima', 'top-center', 'error');
                    })
                    .finally(() => {
                        setSubmiting(false)
                    })
            } else {
                toastMessageCall('Tugas gagal diterima', 'top-center', 'error');
            }
        });
}

const approve = (e) => {
    e.preventDefault()

    // console.log(xket, xskill, xkesalahan);
    // return false

    if (jenis === "Tugas Rutin") {
        if (!jmlAtasan || jmlKoreksi === null) {
            toastMessageCall('Pastikan seluruh field terisi.', 'top-center', 'warning');
            return false
        }

        setSubmiting(true)
        apiWs.post(`/done-rutin/${id}`, {
            jmlAtasan: jmlAtasan,
            jmlKoreksi: jmlKoreksi,
        })
            .then(res => {
                toastMessageCall('Tugas berhasil disetujui', 'top-center', 'success');
                setModal('')
                window.location = '/app-project-list'
            })
            .catch(err => {
                toastMessageCall('Tugas gagal disetujui', 'top-center', 'danger');
            })
            .finally(() => {
                setSubmiting(false)
            })
    }
    else {
        if (!xket) {
            toastMessageCall('Keterangan tidak boleh kosong.', 'top-center', 'warning');
            return false
        }
        // if (xskill.length === 0) {
        //     toastMessageCall('Skill tidak boleh kosong.', 'top-center', 'warning');
        //     return false
        // }

        if (jenis === 'Tugas Event') {

            setSubmiting(true)
            apiWs.post(`/done-event/${id}`, {
                skill: xskill,
                ket: xket,
                kesalahan: xkesalahan
            })
                .then(res => {
                    toastMessageCall('Tugas berhasil disetujui', 'top-center', 'success');
                    setModal('')
                    window.location = '/app-project-list'
                })
                .catch(err => {
                    toastMessageCall('Tugas gagal disetujui', 'top-center', 'danger');
                })
                .finally(() => {
                    setSubmiting(false)
                })

        } else {
            setSubmiting(true)
            apiWs.post(`/done/${id}`, {
                skill: xskill,
                ket: xket,
                kesalahan: xkesalahan
            })
                .then(res => {
                    toastMessageCall('Tugas berhasil disetujui', 'top-center', 'success');
                    setModal('')
                    window.location = '/app-project-list'
                })
                .catch(err => {
                    toastMessageCall('Tugas gagal disetujui', 'top-center', 'danger');
                })
                .finally(() => {
                    setSubmiting(false)
                })

        }
    }
}

const reject = (e) => {
    e.preventDefault()
    swal({
        title: "Apakah anda yakin menolak tugas ini?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (!xket) {
                toastMessageCall('Silahkan input alasan penolakan tugas', 'top-center', 'warning');
                return false
            }
            if (xket.length < 5) {
                toastMessageCall('Silahkan input alasan penolakan, minimal 5 karakter', 'top-center', 'warning');
                return false
            }
            if (willDelete) {
                setSubmiting(true)
                apiWs.post(`/reject/${id}`, {
                    ket: xket
                })
                    .then(res => {
                        toastMessageCall('Tugas berhasil ditolak', 'top-center', 'success');
                        setModal('')
                        window.location = '/app-project-list'
                    })
                    .catch(err => {
                        toastMessageCall('Tugas gagal ditolak', 'top-center', 'error');
                    })
                    .finally(() => {
                        setSubmiting(false)
                    })
            } else {
                toastMessageCall('Tugas gagal ditolak', 'top-center', 'error');
            }
        });
}

const rejectTugas = (e) => {
    e.preventDefault()
    swal({
        title: "Apakah anda yakin menolak tugas ini?",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (!xket) {
                toastMessageCall('Silahkan input alasan penolakan tugas', 'top-center', 'warning');
                return false
            }
            if (xket.length < 5) {
                toastMessageCall('Silahkan input alasan penolakan, minimal 5 karakter', 'top-center', 'warning');
                return false
            }
            if (willDelete) {
                setSubmiting(true)
                apiWs.post(`/reject-tugas/${id}`, {
                    ket: xket
                })
                    .then(res => {
                        toastMessageCall('Tugas berhasil ditolak', 'top-center', 'success');
                        setModal('')
                        window.location = '/app-project-list'
                    })
                    .catch(err => {
                        toastMessageCall('Tugas gagal ditolak', 'top-center', 'error');
                    })
                    .finally(() => {
                        setSubmiting(false)
                    })
            } else {
                toastMessageCall('Tugas gagal ditolak', 'top-center', 'error');
            }
        });
}

const revisi = (e) => {
    e.preventDefault()
    if (!xket) {
        toastMessageCall('Keterangan tidak boleh kosong.', 'top-center', 'warning');
        return false
    }

    const form = new FormData()
    form.append('ket', xket)

    setSubmiting(true)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    if (xFile) {
        for (let i = 0; i < xFile.length; i++) {
            let berkas = xFile[i]
            form.append('files', berkas);
        }
        apiWs.post(`/revisiFiles/${id}`, form, config)
            .then(res => {
                toastMessageCall('Tugas berhasil direvisi', 'top-center', 'success');
                setModal('')
                window.location = '/app-project-list'
            })
            .catch(err => {
                toastMessageCall('Tugas gagal direvisi', 'top-center', 'danger');
            })
            .finally(() => {
                setSubmiting(false)
            })
    } else {
        apiWs.post(`/revisi/${id}`, {
            ket: xket
        })
            .then(res => {
                toastMessageCall('Tugas berhasil direvisi', 'top-center', 'success');
                setModal('')
                window.location = '/app-project-list'
            })
            .catch(err => {
                toastMessageCall('Tugas gagal direvisi', 'top-center', 'danger');
            })
            .finally(() => {
                setSubmiting(false)
            })
    }

}

const hapus = (a) => {

    if (nama.length === 1) {
        toastMessageCall('Gagal hapus pegawai, silahkan hapus tugas ini', 'top-right', 'error')
        return false
    } else {
        swal({
            title: `Apakah anda menghapus ${a} dari tugas ini?`,
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    setSubmiting(true)
                    apiWs.delete(`/tugas/pegawai/${id}`, {
                        params: { a }
                    })
                        .then(res => {
                            toastMessageCall('Berhasil hapus pegawai dari tugas ini', 'top-right', 'success')
                            getData()
                        })
                        .catch(err => {
                            toastMessageCall('Gagal hapus pegawai dari tugas ini', 'top-right', 'error')
                        })
                        .finally(() => {
                            setSubmiting(false)
                        })
                } else {
                    toastMessageCall('Gagal hapus pegawai dari tugas ini', 'top-right', 'error')
                }
            });
    }}



useEffect(() => {
    getData()
}, [])

// AKAN REDIRECT KE DASHBOARD JIKA YG LOGIN BUKAN ADMIN DAN (NPP YG LOGIN TIDAK TERDAPAT PADA LIST NPP PEGAWAI ATAU NPP ATASAN)
useEffect(() => {
    // console.log(kantorIndukTugas);
    if (nppAtasan.length && nppPegawai.length && kantorIndukTugas) {
        // console.log("npp is set");
        // console.log(sessionCred.role !== 'admin', sessionCred.role !== 'direksi');
        // console.log(sessionCred.role !== 'admin' && sessionCred.role !== 'direksi');
        if (sessionCred.role !== 'admin' && sessionCred.role !== 'direksi') {
            // console.log(sessionCred.kantor_induk !== kantorIndukTugas.kantor_induk, !jabatanAtasanTop.includes(sessionCred.jabatan));
            // console.log(sessionCred.kantor_induk !== kantorIndukTugas.kantor_induk || !jabatanAtasanTop.includes(sessionCred.jabatan));
            if (sessionCred.kantor_induk !== kantorIndukTugas.kantor_induk || !jabatanAtasanTop.includes(sessionCred.jabatan)) {
                // console.log(!nppPegawai.includes(sessionCred.npp), !nppAtasan.includes(sessionCred.npp));
                // console.log(!nppPegawai.includes(sessionCred.npp) && !nppAtasan.includes(sessionCred.npp));
                if (!nppPegawai.includes(sessionCred.npp) && !nppAtasan.includes(sessionCred.npp)) {
                    window.location = '/'
                }
            }
        }
    }
}, [nppAtasan, nppPegawai, kantorIndukTugas])

useEffect(() => {
    if (nama.length) {
        api.get(`/kantor/${nama[0].kantor}`)
            .then((result) => {
                setKantorIndukTugas(JSON.parse(result.data.data[0]))
            })
    }
}, [nama])


return (
    <>
        {isLoading ? <Loader /> : (
            <div className='animated fadeIn'>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-md-6 col-sm-12">
                                <h2>Detail Penugasan</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Worksheet</Link></li>
                                        <li className="breadcrumb-item"><Link to="/">Job Desk</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Detail Penugasan</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    {nama.map((d, i) => (
                        <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
                            <div className="card c_grid c_yellow">
                                <div className="body text-center ribbon">
                                    <div className="circle">
                                        <img className="rounded-circle" src="../assets/images/xs/user.png" alt="" />
                                    </div>
                                    <h6 className="mt-3 mb-0">{d.nama_pegawai}</h6>
                                    <br />
                                    {sessionCred.role === 'admin' && status !== "Selesai" && <button className="btn btn-sm btn-danger" onClick={() => hapus(d.nama_pegawai)} disabled={submiting}>Hapus</button>}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="col-12">
                        <div className="card">
                            <div className="body">
                                <ul className="list-group">
                                    <li className="list-group-item"><p>Judul : {judul}</p></li>
                                    <li className="list-group-item">
                                        <p>Jenis Tugas : <JenisHandler jenis={jenis} /></p>
                                    </li>
                                    <li className="list-group-item"><p>Diajukan ke : {atasan}</p></li>
                                    <li className="list-group-item"><p>Tgl Penugasan : {moment(start).format('D MMMM YYYY')}</p></li>
                                    <li className="list-group-item"><p>Tgl Akhir : {moment(end).format('D MMMM YYYY')}</p></li>
                                    {nominal && <li className="list-group-item"><p>Jumlah :  {nominal}</p></li>}
                                    {(jenis !== "Tugas Event" || sessionCred.role === "admin") && (
                                        <li className="list-group-item"><p>Tgl Expired : {moment(exp).format('D MMMM YYYY') === 'Invalid date' ? '-' : moment(exp).format('D MMMM YYYY')}</p></li>
                                    )}
                                    <li className="list-group-item"><p>Triwulan : {triwulan}</p></li>
                                    <li className="list-group-item"><p>Tahun : {triwulanTahun}</p></li>
                                    {nominal !== jmlAtasan && status === "Selesai" && jenis === "Tugas Rutin" && <li className="list-group-item"><p>Jumlah dari Atasan :  {jmlAtasan}</p></li>}
                                    {nominal && status === "Selesai" && <li className="list-group-item"><p>Jumlah Koreksi :  {jmlKoreksi}</p></li>}
                                    <li className="list-group-item"><p>Nilai : {nilai}</p></li>
                                    {jenis !== "Tugas Rutin" && <li className="list-group-item"><p>Skill : {status === "Selesai" && dSkill.map((d, i, array) => i + 1 === array.length ? d.skill : (d.skill + ", "))}</p></li>}
                                    {jenis !== "Tugas Rutin" && <li className="list-group-item"><p>Kesalahan : {kesalahanPegawai.map((d, i, array) => i + 1 === array.length ? d.kesalahan : (d.kesalahan + ", "))}</p></li>}
                                    <li className="list-group-item"><p>Jumlah Kesalahan : {kesalahanPegawai.length ? kesalahanPegawai.length : ""}</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="body">
                            <div className='row'>
                                <div className='col-md-12 col-sm-12 mb-3'>
                                    <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>URAIAN</h5>
                                    <p className="mb-0 mx-3">{tugas}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 col-sm-12 mb-3 d-flex justify-content-center'>
                                    <span><StatusHandler status={status} /></span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 col-sm-12 mb-3 d-flex justify-content-center'>
                                    {sessionCred.role !== 'admin' && status === "Dikerjakan" && jenis !== "Tugas Rutin" && jenis !== "Tugas Event" && sessionCred.npp == nppAtasan && <button className="btn btn-primary btn-sm mr-1" onClick={() => setModal('Modal With Button')}>Revisi</button>}{' '}
                                    {sessionCred.role !== 'admin' && status === "Dikerjakan" && jenis !== "Tugas Rutin" && sessionCred.npp == nppAtasan && <button className="btn btn-success btn-sm mr-1" onClick={() => setModal('Modal Approve')}>Disetujui</button>}{' '}
                                    {sessionCred.role !== 'admin' && status === "Dikerjakan" && jenis !== "Tugas Rutin" && sessionCred.npp == nppAtasan && <button className="btn btn-danger btn-sm mr-1" onClick={() => setModal('Modal Tolak Non Rutin')}>Ditolak</button>}{' '}
                                    {sessionCred.role !== 'admin' && status === "Dikerjakan" && jenis === "Tugas Rutin" && sessionCred.npp == nppAtasan && <button className="btn btn-danger btn-sm mr-1" onClick={() => setModal('Modal Tolak Rutin')}>Tolak</button>}{' '}
                                    {sessionCred.role !== 'admin' && status === "Dikerjakan" && jenis === "Tugas Rutin" && sessionCred.npp == nppAtasan && <button className="btn btn-success btn-sm mr-1" onClick={() => { setModal('Modal Approve Rutin'); setJmlAtasan(nominal) }}>Disetujui</button>}{' '}
                                    {sessionCred.role !== 'admin' && status === "Menunggu" && jenis !== "Tugas Event" && sessionCred.npp == nppAtasan &&
                                        <>
                                            <button className='btn btn-sm btn-danger mr-1' onClick={() => {
                                                setModal('Modal Tolak')
                                            }}
                                            >
                                                Ditolak</button>{' '}
                                            <Link to={`/tugas/revisi/${id}`} className='btn btn-sm btn-info mr-1'>Review</Link>{' '}
                                            <button className='btn btn-sm btn-success mr-1' onClick={() => approveTerima(id)}>Diterima</button>
                                        </>}{' '}
                                    {sessionCred.role !== 'admin' && (status === "Diterima" || status === "Diterima By System") && jenis !== "Tugas Event" && sessionCred.npp == nppAtasan && <Link to={`/tugas/update/${id}`} className="btn btn-success btn-sm mr-1">Ubah</Link>}{' '}
                                    {sessionCred.role === 'admin' && (status === "Tidak Dikerjakan" || status === "Tidak Diselesaikan") && (
                                        <BtnSelesaiByAdmin idTugas={id} successAction={() => window.location.reload()} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    &nbsp;
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="table-responsive">
                                <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>LAMPIRAN PENUGASAN</h5>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th><b>Nama Berkas</b></th>
                                            <th style={{ textAlign: 'center' }}><b>Aksi</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {file.filter((e) => e.status === "Penugasan").map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.path}</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(d.path.endsWith(".jpg") || d.path.endsWith(".jpeg") || d.path.endsWith(".png") || d.path.endsWith(".bmp") || d.path.endsWith(".pdf")) ?
                                                        (<><button className='btn btn-sm btn-primary' onClick={() => preview(d.path, 'penugasan')}>Lihat</button>{` `}</>)
                                                        : null}
                                                    <button className='btn btn-sm btn-primary' onClick={() => downloadPenugasan(d.path)}>Unduh</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="table-responsive">
                                <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>LAMPIRAN</h5>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th><b>Nama Berkas</b></th>
                                            <th style={{ textAlign: 'center' }}><b>Aksi</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {file.filter((e) => e.status === "Dikerjakan").map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.path}</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(d.path.endsWith(".jpg") || d.path.endsWith(".jpeg") || d.path.endsWith(".png") || d.path.endsWith(".bmp") || d.path.endsWith(".pdf")) ?
                                                        (<><button className='btn btn-sm btn-primary' onClick={() => preview(d.path)}>Lihat</button>{` `}</>)
                                                        : null}
                                                    <button className='btn btn-sm btn-primary' onClick={() => download(d.path)}>Unduh</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            {history.filter((v) => v.status === "Dikerjakan").length > 0 && <><b className='ml-2'>Keterangan : </b>{history.filter((v) => v.status === "Dikerjakan")[0].keterangan}</>}
                        </div>
                    </div>
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="table-responsive">
                                <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>LAMPIRAN REVISI</h5>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th><b>Nama Berkas</b></th>
                                            <th style={{ textAlign: 'center' }}><b>Aksi</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {file.filter((e) => e.status === "Revisi").map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.path}</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {(d.path.endsWith(".jpg") || d.path.endsWith(".jpeg") || d.path.endsWith(".png") || d.path.endsWith(".bmp") || d.path.endsWith(".pdf")) ?
                                                        (<><button className='btn btn-sm btn-primary' onClick={() => preview(d.path, 'revisi')}>Lihat</button>{` `}</>)
                                                        : null}
                                                    <button className='btn btn-sm btn-primary' onClick={() => downloadRevisi(d.path)}>Unduh</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-none border">
                        <div className="card-body p-3">
                            <div className="table-responsive">
                                <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>RIWAYAT</h5>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th><b>Keterangan</b></th>
                                            <th><b>Status</b></th>
                                            <th><b>Timestamp</b></th>
                                            <th><b>Action By</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {history.map((d, i) => (
                                            <tr key={i}>
                                                <td>{d.keterangan}</td>
                                                <td><StatusHandler status={d.status} /></td>
                                                <td>{moment(d.cts).format('D MMMM YYYY HH:mm:ss')}</td>
                                                <td>{d.nama_approval}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`modal fade  ${isModal === 'Modal With Button' ? 'd-block show' : ''}`} id="exampleModal">
                    <div className="modal-dialog" role="document">
                        <form onSubmit={(e) => revisi(e)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Revisi Tugas</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                        setModal('')
                                        setXket('')
                                    }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <textarea onChange={e => setXket(e.target.value)} rows={4} className="form-control no-resize" value={xket} placeholder="Silahkan isi keterangan" defaultValue={""} />
                                    </div>
                                    <div className="form-group">
                                        <section className="container">
                                            <ul>
                                                <li>Max upload 10 berkas</li>
                                                <li>Masing-masing ukuran maksimal 20Mb</li>
                                            </ul>
                                            <div {...getRootProps({ className: 'dropzone', style: { width: "100%", height: "40%", border: "1px solid grey" } })}>
                                                <input {...getInputProps()} /><p style={{ textAlign: 'center' }}>Untuk upload lampiran revisi, klik disini</p>
                                            </div>
                                            <ul>
                                                {xFile.length >= 1 && xFile.map((d) => (
                                                    <li key={d.name}>
                                                        {d.name} - {formatBytes(d.size)} bytes
                                                    </li>
                                                ))}
                                            </ul>
                                        </section>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-round btn-info" disabled={submiting}>Revisi</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={`modal fade  ${isModal === 'Modal Approve' ? 'd-block show' : ''}`} id="exampleModal">
                    <div className="modal-dialog" role="document">
                        <form onSubmit={(e) => approve(e)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Review Tugas</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                        setModal('')
                                        setXket('')
                                        setXskill([])
                                        setXkesalahan('')
                                        setKesalahanChanged(false)
                                    }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <textarea onChange={e => setXket(e.target.value)} rows={4} className="form-control no-resize" value={xket} placeholder="Silahkan isi keterangan" defaultValue={""} />
                                    </div>
                                    <div className="form-group">
                                        {/* <Multiselect
                                        options={skill}
                                        displayValue="name"
                                        multiple="multiple"
                                        placeholder="Silahkan pilih soft skill"
                                    /> */}
                                        <select multiple={true} className="custom-select" id="inputGroupSelect01" name='skill' value={xskill} onChange={e => handleChange(e)}>
                                            <option value=''>--Silahkan Pilih Skill--</option>
                                            {skill.map((d, i) => (
                                                <option key={i} defaultValue={d[1]}>{d[1]}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <select className="custom-select" id="inputGroupSelect01" name='kesalahan' value={xkesalahan} onChange={(e) => {
                                            setKesalahanChanged(true)
                                            handleChange(e)
                                        }}>
                                            <option value=''>--Silahkan Pilih Kesalahan--</option>
                                            {kesalahan.map((d, i) => (
                                                <option key={i} defaultValue={d[1]}>{d[1]}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {kesalahanChanged ?
                                        <span>Inputan kesalahan ini akan diakumulasi pada Indikator KPI Frekuensi Kesalahan setiap triwulan.</span> : ""}
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-round btn-success" disabled={submiting}>Disetujui</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={`modal fade  ${isModal === 'Modal Approve Rutin' ? 'd-block show' : ''}`} id="exampleModal">
                    <div className="modal-dialog" role="document">
                        <form onSubmit={(e) => approve(e)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Review Tugas</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                        setModal('');
                                        setJmlAtasan('');
                                        setJmlKoreksi(0);
                                        setKesalahanChanged(false)
                                    }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        Jumlah Transaksi/Pembukaan/Pendaftaran
                                        <input onChange={e => setJmlAtasan(e.target.value)} className='form-control' type='number' placeholder='Inputkan Jumlah Transaksi/Pembukaan/Pendaftaran' value={jmlAtasan} />
                                    </div>
                                    <div className='form-group'>
                                        Jumlah Koreksi/Kesalahan
                                        <input onChange={(e) => {
                                            setJmlKoreksi(e.target.value)
                                            setKesalahanChanged(true)
                                        }} className='form-control' type='number' placeholder='Inputkan Koreksi/Kesalahan' value={jmlKoreksi} />
                                    </div>
                                    {kesalahanChanged ?
                                        <span>Inputan kesalahan ini akan diakumulasi pada Indikator KPI Frekuensi Kesalahan setiap triwulan.</span> : ""}
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-round btn-success" disabled={submiting}>Disetujui</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={`modal fade  ${isModal === 'Modal Tolak Rutin' ? 'd-block show' : ''}`} id="exampleModal">
                    <div className="modal-dialog" role="document">
                        <form onSubmit={(e) => reject(e)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Tolak Tugas</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <textarea onChange={e => setXket(e.target.value)} rows={4} className="form-control no-resize" placeholder="Silahkan isi keterangan" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-round btn-info" disabled={submiting}>Tolak</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={`modal fade  ${isModal === 'Modal Tolak Non Rutin' ? 'd-block show' : ''}`} id="exampleModal">
                    <div className="modal-dialog" role="document">
                        <form onSubmit={(e) => rejectTugas(e)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Tolak Tugas</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <textarea onChange={e => setXket(e.target.value)} rows={4} className="form-control no-resize" placeholder="Silahkan isi keterangan" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-round btn-info" disabled={submiting}>Tolak</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={`modal fade  ${isModal === 'Modal Tolak' ? 'd-block show' : ''}`} id="exampleModal">
                    <div className="modal-dialog" role="document">
                        <form onSubmit={(e) => reject(e)}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Tolak Tugas</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <textarea onChange={e => setXket(e.target.value)} rows={4} className="form-control no-resize" placeholder="Silahkan isi keterangan" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-round btn-danger" disabled={submiting}>Tolak</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={`modal fade  ${isModal === 'Modal Preview' ? 'd-block show' : ''}`} id="exampleModal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Lampiran Tugas</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                    setModal('')
                                    if (lampiranType === 'image') {
                                        lampiranTugasImageRef.current.src = null
                                    }
                                    else if (lampiranType === 'pdf') {
                                        lampiranTugasPdfRef.current.src = null
                                    }
                                    setLampiranType(null)
                                }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {lampiranType === 'image' && (
                                    <div>
                                        <img src="" alt="" id="lampiran_tugas" ref={lampiranTugasImageRef} style={{
                                            objectFit: `contain`,
                                            width: 400
                                        }} />
                                    </div>
                                )}
                                {lampiranType === 'pdf' && (
                                    <div>
                                        <iframe ref={lampiranTugasPdfRef} src="" width="100%" height="400px"></iframe>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-round btn-danger" onClick={() => {
                                    setModal('')
                                    setModal('')
                                    if (lampiranType === 'image') {
                                        lampiranTugasImageRef.current.src = null
                                    }
                                    else if (lampiranType === 'pdf') {
                                        lampiranTugasPdfRef.current.src = null
                                    }
                                    setLampiranType(null)
                                }}>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
)
}
export default DetailProjectList