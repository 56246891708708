import React, { useState, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import { api, apiWs } from "../../configs/axios";
import {
  FormatDate,
  JenisHandler,
  StatusHandler,
} from "../Worksheet/TabelWorksheet";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import Loader from "../Components/Loader";

// IMPORT AUTOCOMPLETE
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getCookie } from "../../helpers/CookieManager";

const Dashboard12 = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  // const token = window.sessionStorage.getItem("token");
  const token = getCookie('token');
  const tokenBody = token.split(".")[1];
  const result = JSON.parse(atob(tokenBody));

  const [sessionCred, setSessionCred] = useState(result.sub);

  const [isLoading, setIsLoading] = useState(false);

  const [customTanggal, setCustomTanggal] = useState("");
  const [kantorInduk, setKantorInduk] = useState([]);
  const [selectedKantorInduk, setSelectedKantorInduk] = useState("");

  const [dataToday, setDataToday] = useState([]);
  const [dataDeadline, setDataDeadline] = useState([]);
  // const [proses, setProses] = useState(0);
  const [expired, setExpired] = useState(0);
  const [tidak_dikerjakan, setTidakDikerjakan] = useState(0);
  const [selesai, setSelesai] = useState(0);

  // TOP 5 BY PEGAWAI
  const [transaksiTertinggiByPegawai, setTransaksiTertinggiByPegawai] =
    useState([]);
  const [transaksiTerendahByPegawai, setTransaksiTerendahByPegawai] = useState(
    []
  );
  const [pembukaanRekTertinggiByPegawai, setpembukaanRekTertinggiByPegawai] =
    useState([]);
  const [pembukaanRekTerendahByPegawai, setPembukaanRekTerendahByPegawai] =
    useState([]);
  const [kanalTertinggiByPegawai, setKanalTertinggiByPegawai] = useState([]);
  const [kanalTerendahByPegawai, setKanalTerendahByPegawai] = useState([]);

  // TOP 5 BY UNIT
  const [transaksiTertinggiByUnit, setTransaksiTertinggiByUnit] = useState([]);
  const [transaksiTerendahByUnit, setTransaksiTerendahByUnit] = useState([]);
  const [pembukaanRekTertinggiByUnit, setpembukaanRekTertinggiByUnit] =
    useState([]);
  const [pembukaanRekTerendahByUnit, setPembukaanRekTerendahByUnit] = useState(
    []
  );
  const [kanalTertinggiByUnit, setKanalTertinggiByUnit] = useState([]);
  const [kanalTerendahByUnit, setKanalTerendahByUnit] = useState([]);

  // TOP 5 BY INDUK
  const [transaksiTertinggiByInduk, setTransaksiTertinggiByInduk] = useState(
    []
  );
  const [transaksiTerendahByInduk, setTransaksiTerendahByInduk] = useState([]);
  const [pembukaanRekTertinggiByInduk, setpembukaanRekTertinggiByInduk] =
    useState([]);
  const [pembukaanRekTerendahByInduk, setPembukaanRekTerendahByInduk] =
    useState([]);
  const [kanalTertinggiByInduk, setKanalTertinggiByInduk] = useState([]);
  const [kanalTerendahByInduk, setKanalTerendahByInduk] = useState([]);

  const [maxRowUnit, setMaxRowUnit] = useState(3);

  const dateFormatter = (DateX = null) => {
    if (DateX) {
      var today = new Date(DateX);
    } else {
      var today = new Date();
    }
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var date = yyyy + "-" + mm + "-" + dd;
    return { date, dd, mm, yyyy };
  };

  const getData = (selectedKantorInduk, DateX = dateFormatter(), loadDefault = true) => {
    // console.log(selectedKantorInduk, DateX);
    // return false
    setIsLoading(true);

    var date = DateX.yyyy + "-" + DateX.mm + "-" + DateX.dd;
    var startDate =
      DateX.yyyy + "-" + DateX.mm + "-" + DateX.dd + " 00:00:01.0+0000";
    var endDate =
      DateX.yyyy + "-" + DateX.mm + "-" + DateX.dd + " 23:59:59.0+0000";

    const filter = {
      startDate,
      endDate,
      date,
      default: loadDefault,
      kantorInduk: selectedKantorInduk,
    };

    // console.log(filter);

    apiWs
      .get(`/dashboard`, {
        params: filter,
      })
      .then((res) => {
        // console.log(res.data);
        // setProses(res.data.dalam_proses);
        setExpired(res.data.expired);
        setTidakDikerjakan(res.data.tidak_dikerjakan);
        setSelesai(res.data.selesai);

        if (res.data.hari_ini) {
          var ar = [];
          res.data.hari_ini.map((item) => {
            ar.push(JSON.parse(item));
          });
          setDataToday(ar);
        }

        if (res.data.deadline) {
          var br = [];
          res.data.deadline.map((item) => {
            br.push(JSON.parse(item));
          });
          setDataDeadline(br);
        }
        // TOP 5 BY PEGAWAI
        setTransaksiTertinggiByPegawai(res.data.transaksiTertinggiByPegawai);
        setTransaksiTerendahByPegawai(res.data.transaksiTerendahByPegawai);
        setpembukaanRekTertinggiByPegawai(
          res.data.pembukaanRekTertinggiByPegawai
        );
        setPembukaanRekTerendahByPegawai(
          res.data.pembukaanRekTerendahByPegawai
        );
        setKanalTertinggiByPegawai(res.data.kanalTertinggiByPegawai);
        setKanalTerendahByPegawai(res.data.kanalTerendahByPegawai);

        // TOP 5 BY UNIT
        setTransaksiTertinggiByUnit(
          res.data.topFiveByUnit.transaksiTertinggiByUnit
        );
        setTransaksiTerendahByUnit(
          res.data.topFiveByUnit.transaksiTerendahByUnit
        );
        setpembukaanRekTertinggiByUnit(
          res.data.topFiveByUnit.pembukaanRekTertinggiByUnit
        );
        setPembukaanRekTerendahByUnit(
          res.data.topFiveByUnit.pembukaanRekTerendahByUnit
        );
        setKanalTertinggiByUnit(res.data.topFiveByUnit.kanalTertinggiByUnit);
        setKanalTerendahByUnit(res.data.topFiveByUnit.kanalTerendahByUnit);

        // TOP 5 BY INDUK
        setTransaksiTertinggiByInduk(
          res.data.topFiveByInduk.transaksiTertinggiByUnit
        );
        setTransaksiTerendahByInduk(
          res.data.topFiveByInduk.transaksiTerendahByUnit
        );
        setpembukaanRekTertinggiByInduk(
          res.data.topFiveByInduk.pembukaanRekTertinggiByUnit
        );
        setPembukaanRekTerendahByInduk(
          res.data.topFiveByInduk.pembukaanRekTerendahByUnit
        );
        setKanalTertinggiByInduk(res.data.topFiveByInduk.kanalTertinggiByUnit);
        setKanalTerendahByInduk(res.data.topFiveByInduk.kanalTerendahByUnit);
        setCustomTanggal(res.data.tglKemarin);
      })
      .catch((err) => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function customDashboardHandler(e) {
    e.preventDefault();
    getData(selectedKantorInduk, dateFormatter(customTanggal), false);
  }

  function _getKantorInduk() {
    api.get("/kantor").then((res) => {
      var ar = [];
      if (res.data.data.length) {
        res.data.data.map((item) => {
          ar.push(JSON.parse(item).kantor_induk);
        });
      }
      ar = [...new Set(ar)];
      setKantorInduk(ar);
    });
  }

  useEffect(() => {
    let kantorIndukDefault = "";
    if (sessionCred.role !== "admin" && sessionCred.role !== "direksi") {
      kantorIndukDefault = sessionCred.kantor_induk;
      setSelectedKantorInduk(sessionCred.kantor_induk);
    }
    getData(kantorIndukDefault);
    _getKantorInduk();
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : (
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-md-6 col-sm-12">
                <h1>Dashboard</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Worksheet</a>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-md-5 col-sm-12">
                <div className="card m-0">
                  <div className="body">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend w-50">
                        <label className="input-group-text w-100">
                          Masukkan Tanggal
                        </label>
                      </div>
                      <div className="input-group-prepend w-50">
                        <Flatpickr
                          // className='form-controll'
                          value={customTanggal}
                          options={{
                            // minDate: "today",
                            altInput: true,
                            altFormat: "j F Y",
                            dateFormat: "Y-m-d",
                            onChange: (selectedDates, dateStr, instance) => {
                              setCustomTanggal(dateStr);
                            },
                          }}
                          className="fp-override"
                        />
                      </div>
                    </div>
                    {(sessionCred.role === "admin" ||
                      sessionCred.role === "direksi") && (
                        <div className="input-group mb-3">
                          <div className="input-group-prepend w-50">
                            <label className="input-group-text w-100">
                              Masukkan Kantor Induk
                            </label>
                          </div>
                          <div className="input-group-prepend w-50">
                            {/* <ThemeProvider theme={darkTheme}> */}
                            <Autocomplete
                              id="combo-box-demo"
                              size="small"
                              options={kantorInduk}
                              autoHighlight
                              value={selectedKantorInduk || null}
                              onChange={(e, v) => {
                                setSelectedKantorInduk(v);
                              }}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                            {/* </ThemeProvider> */}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-12">
                <button
                  className="btn btn-sm btn-primary h-100"
                  onClick={(e) => customDashboardHandler(e)}
                >
                  Tampilkan Data
                </button>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row clearfix">
                {/* <div className="col-lg-3 col-md-3">
                    <div className="card-wrapper">
                      <div className="card s-widget-top">
                        <div className="front p-3 px-4 bg-info text-light">
                          <div><h4>Jumlah Dalam Proses</h4></div>
                          <div className="py-4 m-0 text-center h2">
                            {proses}
                          </div>
                        </div>
                        <div className="back p-3 px-4 "></div>
                      </div>
                    </div>
                  </div> */}
                <div className="col-lg-4 col-md-4">
                  <div className="card-wrapper flip-left">
                    <div className="card s-widget-top">
                      <div className="front p-3 px-4 bg-danger text-light">
                        <div><h4>Tugas Expired</h4></div>
                        <div className="py-4 m-0 text-center h2">
                          {expired}
                        </div>
                        <div className="d-flex">
                          {/*<small className="text-muted">New income</small>
                          <div className="ml-auto">0%</div>*/}
                        </div>
                      </div>
                      <div className="back p-3 px-4 text-center">
                        {/* <p className="text-light">Per-Periode</p>
                        <SparkLineBoxChart
                          data={[64, 71, 80, 44, 108, 70, 92, 114, 81]}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="card-wrapper flip-left">
                    <div className="card s-widget-top">
                      <div className="front p-3 px-4 bg-warning text-light">
                        <div><h4>Tugas Tidak Dikerjakan</h4></div>
                        <div className="py-4 m-0 text-center h2">
                          {tidak_dikerjakan}
                        </div>
                        <div className="d-flex"></div>
                      </div>
                      <div className="back p-3 px-4 text-center"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="card-wrapper flip-left">
                    <div className="card s-widget-top">
                      <div className="front p-3 px-4 bg-success text-light">
                        <div><h4>Tugas Selesai</h4></div>
                        <div className="py-4 m-0 text-center h2">
                          {selesai}
                        </div>
                        <div className="d-flex">
                          {/*<small className="text-muted">Income</small>
                          <div className="ml-auto"><i className="fa fa-caret-up text-success" />4%</div>*/}
                        </div>
                      </div>
                      <div className="back p-3 px-4 text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="body taskboard b-cyan planned_task">
                  <h6 className="font300 mb-3">Tugas yang Dibuat Hari Ini</h6>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing8">
                      <thead>
                        <tr className="text-center">
                          <th>Kantor Induk</th>
                          <th>Tugas</th>
                          <th>Jenis</th>
                          <th>Tanggal Penugasan</th>
                          <th>Tanggal Akhir</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataToday
                          .filter((e) => e.status !== "Deleted")
                          .map((d, i) => (
                            <tr key={i} className="text-center">
                              <td>{d.kantor_induk}</td>
                              <td>{d.judul}</td>
                              <td>
                                <JenisHandler jenis={d.jenis} />
                              </td>
                              <td>
                                <FormatDate dateString={d.start} />
                              </td>
                              <td>
                                <FormatDate dateString={d.end} />
                              </td>
                              <td>
                                <StatusHandler status={d.status} />
                              </td>
                              {(sessionCred.role === "admin" ||
                                sessionCred.role === "direksi") && (
                                  <td>
                                    <Link
                                      className="btn btn-sm btn-info"
                                      to={`/detail-project-list/${d.id_tugas}`}
                                    >
                                      Detail
                                    </Link>
                                  </td>
                                )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="body taskboard b-cyan planned_task">
                  <h6 className="font300 mb-3">
                    Tugas yang Deadline Hari Ini
                  </h6>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing8">
                      <thead>
                        <tr className="text-center">
                          <th>Kantor Induk</th>
                          <th>Tugas</th>
                          <th>Jenis</th>
                          <th>Tanggal Penugasan</th>
                          <th>Tanggal Akhir</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataDeadline
                          .filter((e) => e.status !== "Deleted")
                          .map((d, i) => (
                            <tr key={i} className="text-center">
                              <td>{d.kantor_induk}</td>
                              <td>{d.judul}</td>
                              <td>
                                <JenisHandler jenis={d.jenis} />
                              </td>
                              <td>
                                <FormatDate dateString={d.start} />
                              </td>
                              <td>
                                <FormatDate dateString={d.end} />
                              </td>
                              <td>
                                <StatusHandler status={d.status} />
                              </td>
                              {(sessionCred.role === "admin" ||
                                sessionCred.role === "direksi") && (
                                  <td>
                                    <Link
                                      className="btn btn-sm btn-info"
                                      to={`/detail-project-list/${d.id_tugas}`}
                                    >
                                      Detail
                                    </Link>
                                  </td>
                                )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h4 className="mb-3">5 Tertinggi dan Terendah</h4>
            </div>
          </div>
          <Tabs defaultActiveKey="cabang" id="uncontrolled-tab-example">
            <Tab eventKey="cabang" title={`Cabang`} tabClassName="custom_tab">
              {/* KELAS A */}
              <section id="top-5-by-unit">
                <h4 className="mb-3">Seluruh Cabang Kelas A</h4>
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Tertinggi</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTertinggiByInduk
                                .filter((row) => row[2] === "A")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTerendahByUnit.map((row, index) => {
                                return (
                                  <tr key={index} className="text-center">
                                    <td>{row[0]}</td>
                                    <td>{row[3]}</td>
                                    <td>{row[2]}</td>
                                    <td>{row[1]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTertinggiByInduk
                                .filter((row) => row[2] === "A")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Pembukaan Rekening Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTerendahByUnit.map((row, index) => {
                                return (
                                  <tr key={index} className="text-center">
                                    <td>{row[0]}</td>
                                    <td>{row[3]}</td>
                                    <td>{row[2]}</td>
                                    <td>{row[1]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTertinggiByInduk
                                .filter((row) => row[2] === "A")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTerendahByUnit.map((row, index) => {
                                return (
                                  <tr key={index} className="text-center">
                                    <td>{row[0]}</td>
                                    <td>{row[3]}</td>
                                    <td>{row[2]}</td>
                                    <td>{row[1]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </section>
              <section id="top-5-by-unit">
                <h4 className="mb-3">Seluruh Cabang Kelas B</h4>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Tertinggi</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTertinggiByInduk
                                .filter((row) => row[2] === "B")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTerendahByInduk
                                .filter((row) => row[2] === "B")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTertinggiByInduk
                                .filter((row) => row[2] === "B")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTerendahByInduk
                                .filter((row) => row[2] === "B")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTertinggiByInduk
                                .filter((row) => row[2] === "B")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTerendahByInduk
                                .filter((row) => row[2] === "B")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="top-5-by-unit">
                <h4 className="mb-3">Seluruh Cabang Kelas C</h4>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Tertinggi</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTertinggiByInduk
                                .filter((row) => row[2] === "C")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTerendahByInduk
                                .filter((row) => row[2] === "C")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTertinggiByInduk
                                .filter((row) => row[2] === "C")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTerendahByInduk
                                .filter((row) => row[2] === "C")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTertinggiByInduk
                                .filter((row) => row[2] === "C")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTerendahByInduk
                                .filter((row) => row[2] === "C")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Tab>
            <Tab
              eventKey="capem"
              title={`Cabang Pembantu`}
              tabClassName="custom_tab"
            >
              {/* KELAS A */}
              <section id="top-5-by-unit">
                <h4 className="mb-3">Kantor Cabang Pembantu Kelas A</h4>
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Tertinggi</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTertinggiByUnit
                                .filter((row) => row[2] === "A")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTerendahByUnit.map((row, index) => {
                                return (
                                  <tr key={index} className="text-center">
                                    <td>{row[0]}</td>
                                    <td>{row[3]}</td>
                                    <td>{row[2]}</td>
                                    <td>{row[1]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTertinggiByUnit
                                .filter((row) => row[2] === "A")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Pembukaan Rekening Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTerendahByUnit.map((row, index) => {
                                return (
                                  <tr key={index} className="text-center">
                                    <td>{row[0]}</td>
                                    <td>{row[3]}</td>
                                    <td>{row[2]}</td>
                                    <td>{row[1]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTertinggiByUnit
                                .filter((row) => row[2] === "A")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTerendahByUnit.map((row, index) => {
                                return (
                                  <tr key={index} className="text-center">
                                    <td>{row[0]}</td>
                                    <td>{row[3]}</td>
                                    <td>{row[2]}</td>
                                    <td>{row[1]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </section>
              <section id="top-5-by-unit">
                <h4 className="mb-3">Kantor Cabang Pembantu Kelas B</h4>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Tertinggi</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTertinggiByUnit
                                .filter((row) => row[2] === "B")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTerendahByUnit
                                .filter((row) => row[2] === "B")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTertinggiByUnit
                                .filter((row) => row[2] === "B")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTerendahByUnit
                                .filter((row) => row[2] === "B")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTertinggiByUnit
                                .filter((row) => row[2] === "B")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTerendahByUnit
                                .filter((row) => row[2] === "B")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="top-5-by-unit">
                <h4 className="mb-3">Kantor Cabang Pembantu Kelas C</h4>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Tertinggi</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTertinggiByUnit
                                .filter((row) => row[2] === "C")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTerendahByUnit
                                .filter((row) => row[2] === "C")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTertinggiByUnit
                                .filter((row) => row[2] === "C")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTerendahByUnit
                                .filter((row) => row[2] === "C")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTertinggiByUnit
                                .filter((row) => row[2] === "C")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Kelas</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTerendahByUnit
                                .filter((row) => row[2] === "C")
                                .filter((row) => row[4] === "CABANG PEMBANTU")
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[2]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Tab>
            <Tab
              eventKey="fungsional"
              title={`Kantor Fungsional`}
              tabClassName="custom_tab"
            >
              {/* FUNGSIONAL */}
              <section id="top-5-by-unit">
                <h4 className="mb-3">Kantor Fungsional</h4>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Tertinggi</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTertinggiByUnit
                                .filter(
                                  (row) => row[4] === "KANTOR FUNGSIONAL"
                                )
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTerendahByUnit
                                .filter(
                                  (row) => row[4] === "KANTOR FUNGSIONAL"
                                )
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTertinggiByUnit
                                .filter(
                                  (row) => row[4] === "KANTOR FUNGSIONAL"
                                )
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTerendahByUnit
                                .filter(
                                  (row) => row[4] === "KANTOR FUNGSIONAL"
                                )
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTertinggiByUnit
                                .filter(
                                  (row) => row[4] === "KANTOR FUNGSIONAL"
                                )
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Unit</th>
                                <th>Induk</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTerendahByUnit
                                .filter(
                                  (row) => row[4] === "KANTOR FUNGSIONAL"
                                )
                                .filter((row, index) => index < maxRowUnit)
                                .map((row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[3]}</td>
                                      <td>{row[1]}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Tab>
            <Tab
              eventKey="pegawai"
              title={`Pegawai`}
              tabClassName="custom_tab"
            >
              {/* BY PEGAWAI */}
              <section id="top-5-by-unit">
                <h4 className="mb-3">Seluruh Pegawai</h4>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Tertinggi</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Nama</th>
                                <th>Unit</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTertinggiByPegawai.map(
                                (row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[1]}</td>
                                      <td>{row[2]}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">Transaksi Terendah</h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Nama</th>
                                <th>Unit</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transaksiTerendahByPegawai.map(
                                (row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[1]}</td>
                                      <td>{row[2]}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Nama</th>
                                <th>Unit</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTertinggiByPegawai.map(
                                (row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[1]}</td>
                                      <td>{row[2]}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pembukaan Rekening Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Nama</th>
                                <th>Unit</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pembukaanRekTerendahByPegawai.map(
                                (row, index) => {
                                  return (
                                    <tr key={index} className="text-center">
                                      <td>{row[0]}</td>
                                      <td>{row[1]}</td>
                                      <td>{row[2]}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Tertinggi
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Nama</th>
                                <th>Unit</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTertinggiByPegawai.map((row, index) => {
                                return (
                                  <tr key={index} className="text-center">
                                    <td>{row[0]}</td>
                                    <td>{row[1]}</td>
                                    <td>{row[2]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="body taskboard b-cyan planned_task">
                        <h6 className="font300 mb-3">
                          Pendaftaran Kanal Layanan Terendah
                        </h6>
                        <div className="table-responsive">
                          <table className="table table-hover table-custom spacing8">
                            <thead>
                              <tr className="text-center">
                                <th>Nama</th>
                                <th>Unit</th>
                                <th>Jumlah</th>
                              </tr>
                            </thead>
                            <tbody>
                              {kanalTerendahByPegawai.map((row, index) => {
                                return (
                                  <tr key={index} className="text-center">
                                    <td>{row[0]}</td>
                                    <td>{row[1]}</td>
                                    <td>{row[2]}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Tab>
          </Tabs>

          {/*<div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>Sales This Week</h2>
                </div>
                <div className="body">
                  <Piechart />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>Employment Growth</h2>
                </div>
                <div className="body">
                  <EmploymentGrowth />
                </div>
              </div>
            </div>
                        </div>*/}
          {/*<div className="row clearfix">
            <div className="col-lg-6 col-md-12">
              <div className={`card ${isFullScreen ? " fullscreen" : ""}`}>
                <div className="header">
                  <h2>Overview</h2>
                  <ul className="header-dropdown dropdown">
                    <li><span onClick={() => setFullScreen(!isFullScreen)} className="full-screen"><i className="icon-frame"></i></span></li>
                    <li className="dropdown">
                      <span onClick={() => setDropdown(!isDropdown)} className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"></span>
                      <ul className={`dropdown-menu${isDropdown ? " show" : ""}`}>
                        <li><a href="/">Action</a></li>
                        <li><a href="/">Another Action</a></li>
                        <li><a href="/">Something else</a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="body">
                  <OverviewChart />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="header">
                  <h2>Members</h2>
                </div>
                <div className="body">
                  <MembersBarChart />
                </div>
                <div className="card-footer text-center">
                  <div className="row clearfix">
                    <div className="col-6">
                      <h6>350</h6>
                      <span>Users</span>
                    </div>
                    <div className="col-6">
                      <h6>87</h6>
                      <span>VIP</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="header">
                  <h2>Marketing </h2>
                </div>
                <div className="body">
                  <MarketingChart />
                </div>
                <div className="card-footer text-center">
                  <div className="row clearfix">
                    <div className="col-6">
                      <h6>$3,095</h6>
                      <span>Last Month</span>
                    </div>
                    <div className="col-6">
                      <h6>$2,763</h6>
                      <span>This Month</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      )}
    </>
  );
};

export default Dashboard12;
