import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api, apiWs } from '../../configs/axios';
import { useDropzone } from 'react-dropzone';
import formatBytes from '../../helpers/Utils'
import {
    defaultLimit,
    toastMessageCall,
    encrypt
} from '../../helpers/Utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { TabelWorksheet, JenisHandler, StatusHandler, FormatDate } from './TabelWorksheet';
import { getCookie } from '../../helpers/CookieManager';
import Loader from "../Components/Loader";

const DetailUserProjectList = () => {
    const jabatanAtasanTop = [
        'Kepala Divisi',
        'Pemimpin Cabang Utama',
        'Pemimpin Cabang',
        'Pemimpin Cabang Khusus'
    ];

    // const token = window.sessionStorage.getItem('token')
    const token = getCookie('token')
    const tokenBody = token.split('.')[1];
    const result = JSON.parse(atob(tokenBody))

    const [sessionCred, setSessionCred] = useState(result.sub)
    const [kantorIndukTugas, setKantorIndukTugas] = useState()
    const [nppPegawai, setNppPegawai] = useState([])
    const [nppAtasan, setNppAtasan] = useState([])

    const [isModal, setModal] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [dFile, setdFile] = useState([])
    const [nama, setNama] = useState([])
    const [jenis, setJenis] = useState('')
    const [judul, setJudul] = useState('')
    const [atasan, setAtasan] = useState('')
    const [tugas, setTugas] = useState('')
    const [start, setStart] = useState('')
    const [history, setHistory] = useState([])
    const [end, setEnd] = useState('')
    const [file, setFile] = useState('')
    const [ketFile, setKetFile] = useState('')
    const [status, setStatus] = useState('')
    const [nominal, setNominal] = useState('')
    const [exp, setExp] = useState('')
    const [nilai, setNilai] = useState('')
    const [uploading, setUploading] = useState(false)
    const [eventLewatAkhir, setEventLewatAkhir] = useState(false)
    const [tugasLewatExpired, setTugasLewatExpired] = useState(false)
    const [jabatanTidakWajibUploadfile, setJabatanTidakWajibUploadfile] = useState([])

    const lampiranTugasImageRef = useRef(null)
    const lampiranTugasPdfRef = useRef(null)
    const [lampiranType, setLampiranType] = useState(null)

    const xid = useParams()
    const id = xid.id

    function getJabatanTidakWajibUploadfile() {
        let listJabatanTidakWajibUploadfile = [];
        api.get("/jabatan/tidak-wajib-upload-file")
            .then(res => {
                // console.log(res);
                if (res.data.data.length) {
                    res.data.data.map((item) => listJabatanTidakWajibUploadfile.push(item[0]))
                }
            })
        setJabatanTidakWajibUploadfile(listJabatanTidakWajibUploadfile)
    }

    const onDrop = useCallback(async f => {
        try {
            setFile(f)
            // console.log(f)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const preview = (filename, type = 'dikerjakan') => {
        const param = {
            filename
        }
        if (type === 'revisi') {
            apiWs.get(`/downloads/${id}`, {
                params: param,
                responseType: 'blob'
            }).then(res => previewProcess(filename, res))
        } else if (type === 'penugasan') {
            apiWs.get(`/downloads_penugasan/${id}`, {
                params: param,
                responseType: 'blob'
            }).then(res => previewProcess(filename, res))
        }
        else {
            apiWs.get(`/download/${id}`, {
                params: param,
                responseType: 'blob'
            }).then(res => previewProcess(filename, res))
        }
    }

    const previewProcess = (filename, res) => {
        // IF FILE IS PDF
        if (filename.endsWith(`.pdf`)) {
            setLampiranType('pdf')
            setModal('Modal Preview')
            const element = lampiranTugasPdfRef.current
            const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
            element.src = url
        }
        // IF FILE NOT PDF (IMAGE)
        else {
            setLampiranType('image')
            setModal('Modal Preview')
            const element = lampiranTugasImageRef.current
            const url = window.URL.createObjectURL(new Blob([res.data]));
            element.src = url
        }
    }

    const onDropRejected = useCallback(async f => {
        const errorCode = f[0].errors[0].code;
        let errorMessage = "";
        switch (errorCode) {
            case "too-many-files":
                errorMessage = "Jumlah berkas tidak sesuai ketentuan!"
                break;
            case "file-too-large":
                errorMessage = "Ukuran berkas tidak sesuai ketentuan!"
                break;
            default:
                errorMessage = "Berkas tidak sesuai ketentuan!"
                break;
        }
        toastMessageCall(errorMessage, 'top-center', 'warning');
    }, [])

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        // accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [] },
        onDrop,
        onDropRejected,
        maxFiles: 10,
        maxSize: (1000000 * 20),
    })

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!jabatanTidakWajibUploadfile.includes(sessionCred.jabatan)) {
            if (file === '') {
                toastMessageCall('Berkas tidak boleh kosong.', 'top-center', 'warning');
                return false
            }
        }

        if (ketFile === '') {
            toastMessageCall('Keterangan tidak boleh kosong.', 'top-center', 'warning');
            return false
        }

        if (file.length) {
            const form = new FormData()
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            form.append('uploader', sessionCred.nama)
            form.append('ketFile', ketFile)
            for (let i = 0; i < file.length; i++) {
                let berkas = file[i]
                form.append('files', berkas);
            }

            setUploading(true)
            apiWs.post(`/upload/${id}`, form, config)
                .then(res => {
                    toastMessageCall('Berhasil upload tugas', 'top-center', 'success');
                    setModal('')
                    window.location = '/tugas/dalam-proses'
                })
                .catch(err => {
                    toastMessageCall('Gagal upload tugas', 'top-center', 'danger');
                })
                .finally(() => {
                    setUploading(false)
                })
        }
        else {
            const data = {
                uploader: sessionCred.nama,
                ketFile: ketFile,
            }

            setUploading(true)
            apiWs.post(`/dikerjakan/${id}`, data)
                .then(res => {
                    toastMessageCall('Berhasil upload tugas', 'top-center', 'success');
                    setModal('')
                    window.location = '/tugas/dalam-proses'
                })
                .catch(err => {
                    toastMessageCall('Gagal upload tugas', 'top-center', 'danger');
                })
                .finally(() => {
                    setUploading(false)
                })
        }

    }

    const getData = () => {
        setIsLoading(true)
        apiWs.get(`/tugas/${id}`)
            .then(res => {
                var ar = [];
                if (res.data.data.length) {
                    res.data.data.map((item) => {
                        ar.push(JSON.parse(item))
                    });
                    setNppAtasan([ar[0].npp_atasan])
                    setJenis(ar[0].jenis)
                    setJudul(ar[0].judul)
                    setTugas(ar[0].tugas)
                    setStart(ar[0].start)
                    setEnd(ar[0].end)
                    setStatus(ar[0].status)
                    setNominal(ar[0].nominal)
                    setAtasan(ar[0].nama_atasan)
                    setExp(ar[0].exp)
                    setNilai(ar[0].nilai)
                };

                var br = [];
                let nppPegawais = []
                if (res.data.pegawai.length) {
                    res.data.pegawai.map((item) => {
                        br.push(item)
                        nppPegawais.push(item.npp)
                    });
                    setNppPegawai(nppPegawais)
                    setNama(br)
                };

                var dr = [];
                if (res.data.history.length) {
                    res.data.history.map((item) => {
                        dr.push(JSON.parse(item))
                    });

                    dr.sort(function (x, y) {
                        return new Date(x.cts) < new Date(y.cts) ? 1 : -1
                    })
                    setHistory(dr)
                };

                var er = [];
                if (res.data.berkas.length) {
                    res.data.berkas.map((item) => {
                        er.push(JSON.parse(item))
                    });
                    setdFile(er)
                };
                setIsLoading(false)
            })
            .catch(err => {
                toastMessageCall('Gagal terhubung dengan backend. Error : ' + err);
            })
    }

    const download = (filename) => {
        const param = {
            filename
        }
        apiWs.get(`/download/${id}`, {
            params: param,
            responseType: 'blob'
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    }

    const downloadRevisi = (filename) => {
        const param = {
            filename
        }
        apiWs.get(`/downloads/${id}`, {
            params: param,
            responseType: 'blob'
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    }

    const downloadPenugasan = (filename) => {
        const param = {
            filename
        }
        apiWs.get(`/downloads_penugasan/${id}`, {
            params: param,
            responseType: 'blob'
        })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    }

    function cekEventLewatAkhir() {
        const currentDate = new Date()
        const endDate = new Date(end)
        currentDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        // console.log(endDate, currentDate);
        // console.log(endDate < currentDate);
        if (endDate < currentDate && jenis === "Tugas Event") {
            setEventLewatAkhir(true)
        }
    }

    function cekTugasLewatExpired() {
        const currentDate = new Date()
        const expDate = new Date(exp)
        currentDate.setHours(0, 0, 0, 0);
        expDate.setHours(0, 0, 0, 0);
        // console.log(endDate, currentDate);
        // console.log(endDate < currentDate);
        if (expDate < currentDate) {
            setTugasLewatExpired(true)
        }
    }

    function TombolUpload() {
        if (
            sessionCred.role !== 'admin' &&
            nppPegawai.includes(sessionCred.npp) &&
            (status === "Diterima" || status === "Diterima By System" || status === "Dikerjakan" || status === "Revisi") &&
            !eventLewatAkhir &&
            !tugasLewatExpired &&
            !judul.startsWith("SAM Kredit")
        ) {
            return <button className="btn btn-info btn-sm mr-1" onClick={() => setModal('Modal Approve')}>Upload Tugas</button>
        }
        return <></>
    }

    function TombolUbah() {
        if (
            sessionCred.role !== 'admin' &&
            status === "Menunggu" &&
            (jenis !== "Tugas Event" || jenis !== "Tugas Rutin") &&
            nppPegawai.includes(sessionCred.npp) &&
            !judul.startsWith("SAM Kredit")
        ) {
            return <Link to={`/tugas/update/${id}`} className="btn btn-success btn-sm mr-1">Ubah</Link>
        }
        return <></>

    }

    useEffect(() => {
        getData()
        getJabatanTidakWajibUploadfile()
    }, [])

    useEffect(() => {
        cekEventLewatAkhir()
    }, [end])

    useEffect(() => {
        cekTugasLewatExpired()
    }, [exp])

    // useEffect(() => {
    //     console.log(jabatanTidakWajibUploadfile);
    // }, [jabatanTidakWajibUploadfile])

    // AKAN REDIRECT KE DASHBOARD JIKA YG LOGIN BUKAN ADMIN DAN (NPP YG LOGIN TIDAK TERDAPAT PADA LIST NPP PEGAWAI ATAU NPP ATASAN)
    useEffect(() => {
        // console.log(kantorIndukTugas);
        if (nppAtasan.length && nppPegawai.length && kantorIndukTugas) {
            // console.log("npp is set");
            // console.log(sessionCred.role !== 'admin', sessionCred.role !== 'direksi');
            // console.log(sessionCred.role !== 'admin' && sessionCred.role !== 'direksi');
            if (sessionCred.role !== 'admin' && sessionCred.role !== 'direksi') {
                // console.log(sessionCred.kantor_induk !== kantorIndukTugas.kantor_induk, !jabatanAtasanTop.includes(sessionCred.jabatan));
                // console.log(sessionCred.kantor_induk !== kantorIndukTugas.kantor_induk || !jabatanAtasanTop.includes(sessionCred.jabatan));
                if (sessionCred.kantor_induk !== kantorIndukTugas.kantor_induk || !jabatanAtasanTop.includes(sessionCred.jabatan)) {
                    // console.log(!nppPegawai.includes(sessionCred.npp), !nppAtasan.includes(sessionCred.npp));
                    // console.log(!nppPegawai.includes(sessionCred.npp) && !nppAtasan.includes(sessionCred.npp));
                    if (!nppPegawai.includes(sessionCred.npp) && !nppAtasan.includes(sessionCred.npp)) {
                        window.location = '/'
                    }
                }
            }
        }
    }, [nppAtasan, nppPegawai, kantorIndukTugas])

    useEffect(() => {
        if (nama.length) {
            api.get(`/kantor/${nama[0].kantor}`)
                .then((result) => {
                    setKantorIndukTugas(JSON.parse(result.data.data[0]))
                })
        }
    }, [nama])

    // useEffect(() => {
    //     console.log(nppPegawai);
    // }, [nppPegawai])


    return (
        <>
            {isLoading ? <Loader /> : (
                <div className='animated fadeIn'>
                    <ToastContainer />
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row clearfix">
                                <div className="col-md-6 col-sm-12">
                                    <h2>Detail Penugasan</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Worksheet</Link></li>
                                            <li className="breadcrumb-item"><Link to="/">Job Desk</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Detail Penugasan</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        {nama.map((d, i) => (
                            <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
                                <div className="card c_grid c_yellow">
                                    <div className="body text-center ribbon">
                                        <div className="circle">
                                            <img className="rounded-circle" src="../assets/images/xs/user.png" alt="" />
                                        </div>
                                        <h6 className="mt-3 mb-0">{d.nama_pegawai}</h6>
                                        {/* <span><a href="#" className="__cf_email__" data-cfemail="0a606b796564277a65787e6f784a63646c6524696567">[{d.jabatan}]</a></span>
                                <br />
                                <button className="btn btn-default btn-sm">View Profile</button> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="col-12">
                            <div className="card">
                                <div className="body">
                                    <ul className="list-group">
                                        <li className="list-group-item"><p>Judul : {judul}</p></li>
                                        <li className="list-group-item">
                                            <p>Jenis Tugas : <JenisHandler jenis={jenis} /></p>
                                        </li>
                                        {/* <li className="list-group-item">
                                    <p>Prioritas :{prioritas}</p>
                                </li> */}
                                        <li className="list-group-item"><p>Diajukan ke : {atasan}</p></li>
                                        <li className="list-group-item"><p>Tgl Penugasan : {moment(start).format('D MMMM YYYY')}</p></li>
                                        <li className="list-group-item"><p>Tgl Akhir : {moment(end).format('D MMMM YYYY')}</p></li>
                                        {nominal && <li className="list-group-item"><p>Jumlah :  {nominal}</p></li>}
                                        {(jenis !== "Tugas Event" || sessionCred.role === "admin") && (
                                            <li className="list-group-item"><p>Tgl Expired : {moment(exp).format('D MMMM YYYY') === 'Invalid date' ? '-' : moment(exp).format('D MMMM YYYY')}</p></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="body">
                                    <div className='row'>
                                        <div className='col-md-12 col-sm-12 mb-3'>
                                            <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>URAIAN</h5>
                                            <p className="mb-0 mx-3">{tugas}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12 col-sm-12 mb-3 d-flex justify-content-center'>
                                            <span><StatusHandler status={status} /></span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12 col-sm-12 mb-3 d-flex justify-content-center'>
                                            <TombolUpload />
                                            <TombolUbah />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none border">
                            <div className="card-body p-3">
                                <div className="table-responsive">
                                    <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>LAMPIRAN PENUGASAN</h5>
                                    <table className="table table-hover table-custom spacing5">
                                        <thead>
                                            <tr>
                                                <th><b>Nama Berkas</b></th>
                                                <th style={{ textAlign: 'center' }}><b>aksi</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dFile.filter((e) => e.status === "Penugasan").map((d, i) => (
                                                <tr key={i}>
                                                    <td>{d.path}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {(d.path.endsWith(".jpg") || d.path.endsWith(".jpeg") || d.path.endsWith(".png") || d.path.endsWith(".bmp") || d.path.endsWith(".pdf")) ?
                                                            (<><button className='btn btn-sm btn-primary' onClick={() => preview(d.path, 'penugasan')}>Lihat</button>{` `}</>)
                                                            : null}
                                                        <button className='btn btn-sm btn-primary' onClick={() => downloadPenugasan(d.path)}>Unduh</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none border">
                            <div className="card-body p-3">
                                <div className="table-responsive">
                                    <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>LAMPIRAN</h5>
                                    <table className="table table-hover table-custom spacing5">
                                        <thead>
                                            <tr>
                                                <th><b>Nama Berkas</b></th>
                                                <th style={{ textAlign: 'center' }}><b>aksi</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dFile.filter((e) => e.status === "Dikerjakan").map((d, i) => (
                                                <tr key={i}>
                                                    <td>{d.path}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {(d.path.endsWith(".jpg") || d.path.endsWith(".jpeg") || d.path.endsWith(".png") || d.path.endsWith(".bmp") || d.path.endsWith(".pdf")) ?
                                                            (<><button className='btn btn-sm btn-primary' onClick={() => preview(d.path)}>Lihat</button>{` `}</>)
                                                            : null}
                                                        <button className='btn btn-sm btn-primary' onClick={() => download(d.path)}>Unduh</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {history.filter((v) => v.status === "Dikerjakan").length > 0 && <><b className='ml-2'>Keterangan : </b>{history.filter((v) => v.status === "Dikerjakan")[0].keterangan}</>}
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none border">
                            <div className="card-body p-3">
                                <div className="table-responsive">
                                    <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>LAMPIRAN REVISI</h5>
                                    <table className="table table-hover table-custom spacing5">
                                        <thead>
                                            <tr>
                                                <th><b>Nama Berkas</b></th>
                                                <th style={{ textAlign: 'center' }}><b>aksi</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dFile.filter((e) => e.status === "Revisi").map((d, i) => (
                                                <tr key={i}>
                                                    <td>{d.path}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {(d.path.endsWith(".jpg") || d.path.endsWith(".jpeg") || d.path.endsWith(".png") || d.path.endsWith(".bmp") || d.path.endsWith(".pdf")) ?
                                                            (<><button className='btn btn-sm btn-primary' onClick={() => preview(d.path, 'revisi')}>Lihat</button>{` `}</>)
                                                            : null}
                                                        <button className='btn btn-sm btn-primary' onClick={() => downloadRevisi(d.path)}>Unduh</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none border">
                            <div className="card-body p-3">
                                <div className="table-responsive">
                                    <h5 style={{ marginLeft: '15px', textDecorationLine: 'underline' }}>RIWAYAT</h5>
                                    <table className="table table-hover table-custom spacing5 text-center">
                                        <thead>
                                            <tr>
                                                <th><b>Keterangan</b></th>
                                                <th><b>Status</b></th>
                                                <th><b>Timestamp</b></th>
                                                <th><b>Action By</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history.map((d, i) => (
                                                <tr key={i}>
                                                    <td>{d.keterangan}</td>
                                                    <td><StatusHandler status={d.status} /></td>
                                                    <td>{moment(d.cts).format('D MMMM YYYY HH:mm:ss')}</td>
                                                    <td>{d.nama_approval}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={`modal fade  ${isModal === 'Modal Approve' ? 'd-block show' : ''}`} id="exampleModal">
                            <div className="modal-dialog" role="document">
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Upload Lampiran Tugas</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <section className="container">
                                                    <ul>
                                                        <li>Max upload 10 berkas</li>
                                                        <li>Masing-masing ukuran maksimal 20Mb</li>
                                                    </ul>
                                                    <div {...getRootProps({ className: 'dropzone', style: { width: "100%", height: "40%", border: "1px solid grey" } })}>
                                                        <input {...getInputProps()} />
                                                        <p style={{ textAlign: 'center' }}>Untuk upload tugas klik disini {jabatanTidakWajibUploadfile.includes(sessionCred.jabatan) ? `(Tidak Wajib)` : `(Wajib)`}</p>
                                                    </div>
                                                    <ul>
                                                        {file.length >= 1 && file.map((d) => (
                                                            <li key={d.name}>
                                                                {d.name} - {formatBytes(d.size)} bytes
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </section>
                                            </div>
                                            <div className='form-group'>
                                                <section className='container'>
                                                    <textarea
                                                        rows={4}
                                                        className='form-control'
                                                        placeholder='Input keterangan'
                                                        value={ketFile}
                                                        onChange={(e) => setKetFile(e.target.value)}
                                                        disabled={uploading}>

                                                    </textarea>
                                                </section>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-round btn-primary" disabled={uploading}>Kirim</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={`modal fade  ${isModal === 'Modal Preview' ? 'd-block show' : ''}`} id="exampleModal">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Lampiran Tugas</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                                        setModal('')
                                        if (lampiranType === 'image') {
                                            lampiranTugasImageRef.current.src = null
                                        }
                                        else if (lampiranType === 'pdf') {
                                            lampiranTugasPdfRef.current.src = null
                                        }
                                        setLampiranType(null)
                                    }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {lampiranType === 'image' && (
                                        <div>
                                            <img src="" alt="" id="lampiran_tugas" ref={lampiranTugasImageRef} style={{
                                                objectFit: `contain`,
                                                width: 400
                                            }} />
                                        </div>
                                    )}
                                    {lampiranType === 'pdf' && (
                                        <div>
                                            <iframe ref={lampiranTugasPdfRef} src="" width="100%" height="400px"></iframe>
                                        </div>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-round btn-danger" onClick={() => {
                                        setModal('')
                                        setModal('')
                                        if (lampiranType === 'image') {
                                            lampiranTugasImageRef.current.src = null
                                        }
                                        else if (lampiranType === 'pdf') {
                                            lampiranTugasPdfRef.current.src = null
                                        }
                                        setLampiranType(null)
                                    }}>Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default DetailUserProjectList