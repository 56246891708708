import React, { useEffect, useState, useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { api } from '../../configs/axios';
import { tableBodyJabatan } from '../Table/tableJabatan';
import { defaultLimit, toastMessageCall } from '../../helpers/Utils';
import Tooltip from '../common/toolTip';

import 'react-toastify/dist/ReactToastify.css';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import ReactExport from "react-export-excel-hot-fix";
import Loader from "../Components/Loader";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Jabatan = () => {
    const [dataTableJabatan, setDataTableJabatan] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModal, setModal] = useState('');

    const [key, setKey] = useState('');
    const [xId, setxId] = useState('');
    const [xLevel, setxLevel] = useState(2);
    const [xJabatan, setxJabatan] = useState('');
    const [xEvent, setxEvent] = useState('');
    const [xTidakWajibUploadFile, setxTidakWajibUploadFile] = useState('')

    const [tableHeader, setTableHeader] = useState([
        { field: 'jabatan', headerName: 'Jabatan' },
        { field: 'event', headerName: 'Bisa Membuat Tugas Event', cellRendererFramework: (params) => params.data.event ? "Aktif" : "Tidak Aktif" },
        { field: 'tidak_wajib_upload_file', headerName: 'Tidak Wajib Upload File', cellRendererFramework: (params) => params.data.tidak_wajib_upload_file ? "Aktif" : "Tidak Aktif" },
        {
            field: 'id_jabatan', headerName: 'Aksi', cellRendererFramework: (params) =>
                <div>
                    <Tooltip text="Edit" id="Edit" />
                    <button type="button" className="btn btn-sm btn-default" title="Edit" data-for="edit" data-tip onClick={() => {
                        loadData(params.data);
                        setModal('modal-jabatan');
                    }}><i className="icon-pencil"></i></button>{" "}
                    <Tooltip text="Hapus" id="Hapus" />
                    <button type="button" className="btn btn-sm btn-default" title="Hapus" data-for="hapus" data-tip onClick={() => {
                        loadData(params.data);
                        setModal('modal-hapus-jabatan');
                    }}><i className="icon-trash"></i></button>
                </div>
        }
    ])

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    }));

    /** Paging */
    const [pnextPage, setpnextPage] = useState(0);
    const [pDisplay, setpDisplay] = useState('none');

    const loadDataTable = (page) => {
        setLoading(true);
        // var param = { key: '.2', limit: defaultLimit(), start_row: page }; //limited
        var param = { key: '~$~2' }; //load all
        api.get('/jabatan', { params: param }).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.message, 'top-center', 'warning');
                return false;
            };

            if (res.data.data.length < 1 && page == 0) {
                setpDisplay('none');
                return false;
            };

            var ar = dataTableJabatan;
            if (page == 0) {
                ar = []
            };

            if (res.data.jumlah_data > ar.length) {
                if (res.data.data.length) {
                    res.data.data.map((item, i, row) => {
                        if (i + 1 == (row.length)) {
                            let last_item = JSON.parse(item[0]);
                            setpnextPage(last_item.id_jabatan);
                        };
                        ar.push(JSON.parse(item));
                    });
                    setDataTableJabatan(ar);
                    setpDisplay('block');
                };
            } else {
                setpDisplay('none');
            };


        }).catch(err => {
            toastMessageCall('Gagal terhubungan dengan backend. Error : ' + err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const kosongBox = () => {
        setxId('');
        setxJabatan('');
        setxLevel(2);
        setxEvent('')
        setxTidakWajibUploadFile('')
        setKey('');
    };

    const loadData = (item) => {
        setKey(item.id_jabatan + "~$~" + item.level);
        setxId(item.id_jabatan);
        setxJabatan(item.jabatan);
        setxLevel(item.level);
        setxEvent(item.event || false)
        setxTidakWajibUploadFile(item.tidak_wajib_upload_file || false)
    };

    const simpanJabatan = () => {

        if (!xJabatan) {
            toastMessageCall('Jabatan tidak boleh kosong', 'top-center', 'warning');
            return false;
        };

        if (xEvent === '') {
            toastMessageCall('Status event tidak boleh kosong', 'top-center', 'warning');
            return false;
        };

        if (xTidakWajibUploadFile === '') {
            toastMessageCall('Status tidak wajib upload file tidak boleh kosong', 'top-center', 'warning');
            return false;
        };

        // console.log(xEvent);
        // return false

        setLoading(true);
        api.post('/jabatan', {
            key: key,
            jabatan: xJabatan,
            level: 2,
            event: xEvent,
            tidak_wajib_upload_file: xTidakWajibUploadFile
        }).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.message, 'top-center', 'warning');
                return false;
            };
            toastMessageCall(res.data.message, 'top-center', 'success');
            loadDataTable(0);
            kosongBox();
            setModal('');
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const deleteJabatan = () => {
        if (!key) {
            toastMessageCall('Belum ada data yang dipilih', 'top-center', 'warning');
            return false
        };

        setLoading(true);
        api.delete('/jabatan/' + key).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.message, 'top-center', 'error');
                return false;
            };

            toastMessageCall(res.data.message, 'top-center', 'success');
            loadDataTable(0)
            setModal('');
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const nextPage = () => {
        loadDataTable(pnextPage);
    };

    useEffect(() => {
        loadDataTable(0)
    }, []);

    function ExcelExport({ data }) {
        return (
            <ExcelFile filename={`Data Jabatan`} element={
                <button type="button" className="btn btn-sm btn-success btn-block mr-1">
                    Export Data Jabatan ke Excel
                </button>}>
                <ExcelSheet data={data} name="Jabatan">
                    <ExcelColumn label="Jabatan" value="jabatan" />
                    <ExcelColumn label="Bisa Membuat Tugas Event" value="event" />
                </ExcelSheet>
            </ExcelFile>
        );
    }

    return (
        <>
            {loading ? <Loader /> : (
                <>
                    <ToastContainer />
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row clearfix">
                                <div className="col-md-8 col-sm-12">
                                    <h2>Jabatan</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Worksheet</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Jabatan</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-md-2 col-sm-12 text-right">
                                    <button type='button' className='btn btn-sm btn-primary mr-1 mb-2' onClick={() => {
                                        kosongBox();
                                        setModal('modal-jabatan');
                                    }}>Tambah Jabatan</button>
                                </div>
                                <div className="col-md-2 col-sm-12 text-right">
                                    <ExcelExport data={dataTableJabatan} />
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            {/*<div className="col-12">
                        <div className="card">
                            <div className="body">
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-8">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Jabatan" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-4">
                                        <button type='button' className='btn btn-sm btn-primary btn-block'>Cari</button>
                                    </div>
                                </div>
                            </div>
                        </div>
    </div>*/}
                            <div className="col-12">
                                <div className="table-responsive">
                                    <div className="ag-theme-alpine" style={{ width: '100%', height: 700 }}>
                                        <AgGridReact
                                            rowData={dataTableJabatan} // Row Data for Rows
                                            columnDefs={tableHeader} // Column Defs for Columns
                                            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                                            defaultColDef={defaultColDef}
                                            pagination={true}
                                            paginationPageSize={20}
                                        />
                                    </div>
                                    {/* <table className="table table-hover table-custom spacing8">
                                <thead>
                                    <tr>
                                        <th className="w30">NO</th>
                                        <th>JABATAN</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableBodyJabatan(loading, dataTableJabatan, { loadData }, { setModal })}
                                    <tr>
                                        <td colSpan={3} style={{ textAlign: 'center' }}><NavLink to="#" className="text-success" onClick={() => { nextPage(); }} style={{ display: pDisplay }}><i className="fa fa-refresh fa-spin"></i> Tampilkan lebih banyak</NavLink></td>
                                    </tr>
                                </tbody>
                            </table> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`modal fade ${isModal === 'modal-jabatan' ? 'd-block show' : ''}`} >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h5 className='modal-title'>Jabatan</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className='modal-body'>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" style={{ width: '150px' }}>Nama Jabatan</span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="" aria-label="Jabatan" aria-describedby="basic-addon1" value={xJabatan} onChange={(e) => { setxJabatan(e.target.value) }} />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Dapat Membuat Tugas Event</span>
                                        </div>
                                        <select className="custom-select form-control" id="inputGroupSelect01" value={xEvent} onChange={(e) => { setxEvent(e.target.value === 'true' ? true : false) }}>
                                            <option value="">-- Status Event --</option>
                                            <option value={'true'}>Aktif</option>
                                            <option value={'false'}>Tidak Aktif</option>
                                        </select>
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Tidak Wajib Upload File</span>
                                        </div>
                                        <select className="custom-select form-control" id="inputGroupSelect01" value={xTidakWajibUploadFile} onChange={(e) => { setxTidakWajibUploadFile(e.target.value === 'true' ? true : false) }}>
                                            <option value="">-- Pilih Salah Satu --</option>
                                            <option value={'true'}>Ya</option>
                                            <option value={'false'}>Tidak</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='modal-footer'>
                                    <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => {
                                        setModal('');
                                        kosongBox();
                                    }}>Tutup</button>
                                    <button type="button" className="btn btn-round btn-primary" onClick={() => {
                                        simpanJabatan();
                                    }}>Simpan</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`modal fade ${isModal === 'modal-hapus-jabatan' ? 'd-block show' : ''}`} >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h5 className='modal-title'>Jabatan</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className='modal-body text-center pt-4 mb-4'>
                                    <h5>Yakin data akan dihapus?</h5>
                                    <p className="mb-4">Data yang sudah dihapus tidak bisa dikembalikan lagi.</p>
                                </div>
                                <div className='modal-footer'>
                                    <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => {
                                        setModal('');
                                        kosongBox();
                                    }}>Tutup</button>
                                    <button type="button" className="btn btn-round btn-primary" onClick={() => {
                                        deleteJabatan();
                                    }}>Hapus</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default Jabatan;