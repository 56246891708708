import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiWs } from "../../configs/axios";
import moment from "moment";
import {
  TabelWorksheet,
  JenisHandler,
  StatusHandler,
  DateFormatter,
} from "./TabelWorksheet";
import { getCookie } from "../../helpers/CookieManager";
import Loader from "../Components/Loader";

const ProjectList = () => {
  // const token = window.sessionStorage.getItem('token')
  const token = getCookie("token");
  const tokenBody = token.split(".")[1];
  const result = JSON.parse(atob(tokenBody));
  const [isLoading, setIsLoading] = useState(false)
  const [sessionCred, setSessionCred] = useState(result.sub);
  const [isModal, setModal] = useState("");
  const [data, setData] = useState([]);
  const [tableHeader, setTableHeader] = useState([
    { field: "nama_pegawai", headerName: "Nama Pegawai" },
    { field: "jabatan_pegawai", headerName: "Jabatan Pegawai" },
    {
      field: "status",
      headerName: "Status",
      cellRendererFramework: (params) => (
        <StatusHandler status={params.data.status} />
      ),
    },
    { field: "judul", headerName: "Tugas" },
    {
      field: "jenis",
      headerName: "Jenis Tugas",
      cellRendererFramework: (params) => (
        <JenisHandler jenis={params.data.jenis} />
      ),
    },
    {
      field: "start",
      headerName: "Tanggal Penugasan",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.start),
    },
    {
      field: "end",
      headerName: "Tanggal Akhir",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.end),
    },
    {
      field: "exp",
      headerName: "Tanggal Expired",
      type: 'dateColumn',
      valueFormatter: (params) => (
        (params.data.jenis !== "Tugas Event" || sessionCred.role === "admin") ? DateFormatter(params.data.exp) : ""
      )
    },
    {
      field: "id_tugas",
      headerName: "Aksi",
      cellRendererFramework: (params) => (
        <Link
          className="btn btn-sm btn-info"
          to={`/detail-project-list/${params.data.id_tugas}`}
        >
          Detail
        </Link>
      ),
    },
  ]);

  const _getData = (a) => {
    setIsLoading(true)
    if (a) {
      var param = { a, npp: sessionCred.npp }
      apiWs.get("/tugas/dikerjakan", {
        params: param
      }).then((res) => {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        ar.sort(function (x, y) {
          return new Date(x.start) < new Date(y.start) ? 1 : -1;
        });
        // if (sessionCred.role === "admin")
        //   setData(ar.filter((d) => d.status === "Dikerjakan"));
        // else
        //   setData(
        //     ar.filter(
        //       (d) =>
        //         d.status === "Dikerjakan" && d.nama_atasan === sessionCred.nama
        //     )
        //   );
        setData(ar)
        setIsLoading(false)
      });
    } else {
      var param = { npp: sessionCred.npp }
      apiWs.get("/tugas/dikerjakan", {
        params: param
      }).then((res) => {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        ar.sort(function (x, y) {
          return new Date(x.start) < new Date(y.start) ? 1 : -1;
        });
        // if (sessionCred.role === "admin")
        //   setData(ar.filter((d) => d.status === "Dikerjakan"));
        // else
        //   setData(
        //     ar.filter(
        //       (d) =>
        //         d.status === "Dikerjakan" && d.nama_atasan === sessionCred.nama
        //     )
        //   );
        setData(ar)
        setIsLoading(false)
      });
    }
  };

  useEffect(() => {
    _getData();
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : (
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-md-6 col-sm-12">
                <h2>Tugas yang Dikerjakan Bawahan</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Worksheet</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/">Job Desk</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Approval Tugas
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <button className="btn btn-info"
              style={{
                position: "absolute", /* becomes a layer */
                right: '50px', /* relative to its parent wrapper */
                top: '98px', /* relative to its parent wrapper */
                zIndex: 2,
              }}
              onClick={() => _getData('test')}
            >Tampilkan seluruh tugas</button>
            <div className="col-12">
              <TabelWorksheet
                tableData={data}
                tableHeader={tableHeader}
                tableTitle={`Tabel Tugas yang Dikerjakan Bawahan`}
              />
            </div>
          </div>
          {/* <div className={`modal fade Form-Wizard-modal ${isModal === 'Form Wizard modal' ? 'd-block show' : ''}`} onClick={() => setModal('')}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Form Wizard modal</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body pricing_page">
                                <div id="wizard_horizontal">
                                    <h2>First Step</h2>
                                    <section>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ut nulla nunc. Maecenas
                                            arcu sem, hendrerit a tempor quis, sagittis accumsan tellus. In hac habitasse platea
                                            dictumst. Donec a semper dui. Nunc eget quam libero. Nam at felis metus. Nam tellus
                                            dolor, tristique ac tempus nec, iaculis quis nisi. </p>
                                    </section>
                                    <h2>Second Step</h2>
                                    <section>
                                        <p>Donec mi sapien, hendrerit nec egestas a, rutrum vitae dolor. Nullam venenatis diam ac
                                            ligula elementum pellentesque. In lobortis sollicitudin felis non eleifend. Morbi
                                            tristique tellus est, sed tempor elit. Morbi varius, nulla quis condimentum dictum,
                                            nisi elit condimentum magna, nec venenatis urna quam in nisi. Integer hendrerit sapien
                                            a diam adipiscing consectetur. In euismod augue ullamcorper leo dignissim quis elementum
                                            arcu porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum leo
                                            velit, blandit ac tempor nec, ultrices id diam. Donec metus lacus, rhoncus sagittis
                                            iaculis nec, malesuada a diam. Donec non pulvinar urna. Aliquam id velit lacus. </p>
                                    </section>
                                    <h2>Third Step</h2>
                                    <section>
                                        <p> Morbi ornare tellus at elit ultrices id dignissim lorem elementum. Sed eget nisl at justo
                                            condimentum dapibus. Fusce eros justo, pellentesque non euismod ac, rutrum sed quam.
                                            Ut non mi tortor. Vestibulum eleifend varius ullamcorper. Aliquam erat volutpat.
                                            Donec diam massa, porta vel dictum sit amet, iaculis ac massa. Sed elementum dui
                                            commodo lectus sollicitudin in auctor mauris venenatis. </p>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        </div>
      )}
    </>
  );
};

// const OldTableLayout = () => {
//     <div className="card">
//         <div className="body taskboard b-cyan planned_task">
//             <div className="table-responsive">
//                 <table className="table table-hover table-custom spacing8">
//                     <thead>
//                         <tr className="text-center">
//                             <th>Nama</th>
//                             <th>Jabatan</th>
//                             <th>Status</th>
//                             <th>Tugas</th>
//                             <th>Jenis</th>
//                             <th>Tanggal Penugasan</th>
//                             <th>Tanggal Akhir</th>
//                             <th>Tanggal Expired</th>
//                             <th>Aksi</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {data.filter((e) => e.status === "Dikerjakan").map((d, i) => (
//                             <tr key={i} className="text-center">
//                                 <td>{d.nama_pegawai}</td>
//                                 <td>Jabatan</td>
//                                 <td>
//                                     <StatusHandler status={d.status} />
//                                 </td>
//                                 {/* <td><h6 className="mb-0">{d.pegawai[0].nama}</h6></td>
//                             <td><h6 className="mb-0">{d.pegawai[0].jabatan}</h6></td> */}
//                                 <td><span>{d.judul}</span></td>
//                                 <td>
//                                     {d.jenis === "Tugas Utama" && <span className="badge badge-success">Tugas Utama/RBB</span>}
//                                     {d.jenis === "Tugas Tambahan" && <span className="badge badge-primary">Tugas Khusus/Tambahan</span>}
//                                     {d.jenis === "Tugas Rutin" && <span className="badge badge-info">Tugas Rutin Harian</span>}
//                                 </td>
//                                 <td><span>{moment(d.start).format('D MMMM YYYY')}</span></td>
//                                 <td><span>{moment(d.end).format('D MMMM YYYY')}</span></td>
//                                 <td><span>{moment(d.exp).format('D MMMM YYYY')}</span></td>
//                                 <td>
//                                     {/* <button className='btn btn-sm btn-info' onClick={() => {
//                                     setModal('Form Wizard modal')
//                                     _getDetail(d.uraian)
//                                 }}>Detail</button> */}
//                                     <Link className='btn btn-sm btn-info' to={`/detail-project-list/${d.id}`} disabled>Detail</Link>
//                                     {/* <button className='btn btn-sm btn-info' disabled>Detail</button> */}
//                                 </td>
//                             </tr>
//                         ))}
//                         {/* <tr>
//                         <td><img src="../assets/images/xs/user.png" alt="Avatar" className="w30 rounded-circle mr-2" /> <span>[Pegawai 1]</span></td>
//                         <td><h6 className="mb-0">[Jabatan]</h6></td>
//                         <td><span className="badge badge-success">Tugas Utama</span></td>
//                         <td><span>19/01/2023 08:00</span></td>
//                         <td> 8 Jam</td>
//                         <td><span className="text-danger">High</span></td>
//                         <td>
//                             <div className="progress progress-xxs progress-transparent custom-color-green mb-0 mt-0">
//                                 <div className="progress-bar" data-transitiongoal="30" aria-valuenow="30" style={{ width: "30%" }}></div>
//                             </div>
//                         </td>
//                     </tr>
//                     <tr>
//                         <td><img src="../assets/images/xs/user.png" alt="Avatar" className="w30 rounded-circle mr-2" /> <span>[Pegawai 2]</span></td>
//                         <td><h6 className="mb-0">[Jabatan]</h6></td>
//                         <td><span className="badge badge-primary">Tugas Tambahan</span></td>
//                         <td><span>19/01/2023 13:00</span></td>
//                         <td>3 Jam</td>
//                         <td><span className="text-danger">High</span></td>
//                         <td>
//                             <div className="progress progress-xxs progress-transparent custom-color-green mb-0 mt-0">
//                                 <div className="progress-bar" data-transitiongoal="10" aria-valuenow="10" style={{ width: "10%" }}></div>
//                             </div>
//                         </td>
//                     </tr>
//                     <tr>
//                         <td><img src="../assets/images/xs/user.png" alt="Avatar" className="w30 rounded-circle mr-2" /> <span>[Pegawai 3]</span></td>
//                         <td><h6 className="mb-0">[Jabatan]</h6></td>
//                         <td><span className="badge badge-primary">Tugas Tambahan</span></td>
//                         <td><span>19/01/2023 10:00</span></td>
//                         <td>6 Jam</td>
//                         <td><span className='text-warning'>Medium</span></td>
//                         <td>
//                             <div className="progress progress-xxs progress-transparent custom-color-green mb-0 mt-0">
//                                 <div className="progress-bar" data-transitiongoal="50" aria-valuenow="50" style={{ width: "50%" }}></div>
//                             </div>
//                         </td>
//                     </tr>
//                     <tr>
//                         <td><img src="../assets/images/xs/user.png" alt="Avatar" className="w30 rounded-circle mr-2" /> <span>[Pegawai 4]</span></td>
//                         <td><h6 className="mb-0">[Jabatan]</h6></td>
//                         <td><span className="badge badge-success">[Tugas Utama]</span></td>
//                         <td><span>19/01/2023 08:00</span></td>
//                         <td>8 Jam</td>
//                         <td><span>None</span></td>
//                         <td>
//                             <div className="progress progress-xxs progress-transparent custom-color-green mb-0 mt-0">
//                                 <div className="progress-bar" data-transitiongoal="23" aria-valuenow="23" style={{ width: "23%" }}></div>
//                             </div>
//                         </td>
//                     </tr> */}

//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     </div>
// }
export default ProjectList;
