import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Tooltip from "../common/toolTip";
import { apiWs } from "../../configs/axios";
import { toastMessageCall } from "../../helpers/Utils";

import "react-toastify/dist/ReactToastify.css";

import {
  TabelWorksheet,
  FormatDate,
  DateComparator,
  DateFormatter
} from "../Worksheet/TabelWorksheet";

import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import ReactExport from "react-export-excel-hot-fix";
import Loader from "../Components/Loader";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function HariLibur() {
  const [data, setData] = useState([]);
  const [isModal, setModal] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [tanggal, setTanggal] = useState("");
  const [tanggalInit, setTanggalInit] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const [tableHeader, setTableHeader] = useState([
    {
      field: [0],
      headerName: "Tanggal",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data[0]),
    },
    { field: [1], headerName: "Keterangan" },
    {
      field: [0],
      headerName: "Aksi",
      cellRendererFramework: (params) => (
        <div>
          <Tooltip text="Edit" id="Edit" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Edit"
            data-for="edit"
            data-tip
            onClick={() => {
              loadData(params.data);
              setIsUpdate(true);
              setModal("modal-hari-libur");
            }}
          >
            <i className="icon-pencil"></i>
          </button>{" "}
          <Tooltip text="Hapus" id="Hapus" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Hapus"
            data-for="hapus"
            data-tip
            onClick={() => {
              loadData(params.data);
              setModal("modal-hapus");
            }}
          >
            <i className="icon-trash"></i>
          </button>
        </div>
      ),
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  }));

  function loadData(item) {
    setTanggal(item[0]);
    setTanggalInit(item[0]);
    setKeterangan(item[1]);
  }

  function emptyData() {
    setTanggal("");
    setTanggalInit("");
    setKeterangan("");
    setIsUpdate(false);
  }

  function getData() {
    setIsLoading(true)
    emptyData();
    apiWs
      .get(`/hari-libur`)
      .then((res) => {
        res.data.data.sort(function (x, y) {
          return new Date(x[0]) < new Date(y[0]) ? 1 : -1;
        });
        setData(res.data.data);
        setIsLoading(false)
      })
      .catch((error) => toastMessageCall(error, "top-center", "warning"));
  }

  function postData(e) {
    e.preventDefault();
    if (validateForm()) {
      if (isUpdate) {
        apiWs
          .patch(`/hari-libur/${tanggalInit}`, { tanggal, keterangan })
          .then((res) => {
            toastMessageCall(res.data.message, "top-center", "success");
          })
          .catch((error) => toastMessageCall(error, "top-center", "warning"))
          .finally(() => {
            setModal("");
            emptyData();
            getData();
          });
      } else {
        apiWs
          .post(`/hari-libur`, { tanggal, keterangan })
          .then((res) => {
            toastMessageCall(res.data.message, "top-center", "success");
          })
          .catch((error) => toastMessageCall(error, "top-center", "warning"))
          .finally(() => {
            setModal("");
            emptyData();
            getData();
          });
      }
    }
  }

  function validateForm() {
    if (!tanggal) {
      toastMessageCall(
        "Pastikan tanggal hari libur tidak kosong!",
        "top-center",
        "warning"
      );
      return false;
    }
    if (!keterangan) {
      toastMessageCall(
        "Pastikan keterangan hari libur tidak kosong!",
        "top-center",
        "warning"
      );
      return false;
    }
    if (keterangan.length < 5) {
      toastMessageCall(
        "Pastikan keterangan hari libur tidak kurang dari 5 karakter!",
        "top-center",
        "warning"
      );
      return false;
    }
    return true;
  }

  function deleteData() {
    apiWs
      .delete(`/hari-libur/${tanggal}`)
      .then((res) => {
        toastMessageCall(res.data.message, "top-center", "success");
      })
      .catch((error) => toastMessageCall(error, "top-center", "warning"))
      .finally(() => {
        setModal("");
        emptyData();
        getData();
      });
  }

  useEffect(() => {
    getData();
  }, []);

  function ExcelExport({ data }) {
    return (
      <ExcelFile filename={`Data Hari Libur`} element={
        <button type="button" className="btn btn-sm btn-success btn-block mr-1">
          Export Data Hari Libur ke Excel
        </button>}>
        <ExcelSheet data={data} name="Hari Libur">
          <ExcelColumn label="Tanggal" value={[0]} />
          <ExcelColumn label="Keterangan" value={[1]} />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  // useEffect(() => {
  //   console.log(data);
  // }, [data])

  return (
    <>
      {isLoading ? <Loader /> : (
        <div className="animated-fadeIn">
          <ToastContainer />
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-md-8 col-sm-12">
                  <h2>Hari Libur</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Worksheet</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Hari Libur
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-md-2 col-sm-12 text-right">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary btn-block mr-1"
                    onClick={() => {
                      //kosongBox();
                      setModal("modal-hari-libur");
                    }}
                  >
                    Tambah Hari Libur
                  </button>
                </div>
                <div className="col-md-2 col-sm-12 text-right">
                  <ExcelExport data={data} />
                </div>
              </div>
            </div>
            <TabelWorksheet
              tableData={data}
              tableHeader={tableHeader}
              tableTitle={`Tabel Hari Libur`}
            />
          </div>

          <div
            className={`modal fade ${isModal === "modal-hari-libur" ? "d-block show" : ""
              }`}
          >
            <form onSubmit={(e) => postData(e)}>
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Hari Libur</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setModal("");
                        emptyData();
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{ width: "150px" }}
                        >
                          Tanggal
                        </span>
                      </div>
                      <Flatpickr
                        options={{
                          disable: [
                            function (date) {
                              // return true to disable
                              return date.getDay() === 0 || date.getDay() === 6;
                            },
                          ],
                          locale: {
                            firstDayOfWeek: 1, // start week on Monday
                          },
                          altInput: true,
                          altFormat: "j F Y",
                          dateFormat: "Y-m-d",
                          onChange: (selectedDates, dateStr, instance) => {
                            setTanggal(dateStr);
                          },
                        }}
                        name={`tanggal`}
                        placeholder="Klik untuk ubah tanggal"
                        value={tanggal}
                      />
                    </div>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{ width: "150px" }}
                        >
                          Keterangan
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="keterangan"
                        aria-describedby="basic-addon1"
                        value={keterangan}
                        name={`keterangan`}
                        onChange={(e) => setKeterangan(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-round btn-default"
                      data-dismiss="modal"
                      onClick={() => {
                        setModal("");
                        emptyData();
                      }}
                    >
                      Tutup
                    </button>
                    <button type="submit" className="btn btn-round btn-primary">
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div
            className={`modal fade ${isModal === "modal-hapus" ? "d-block show" : ""
              }`}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Hari Libur</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      emptyData();
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body text-center pt-4 mb-4">
                  <h5>Yakin data akan dihapus?</h5>
                  <p className="mb-4">
                    Data yang sudah dihapus tidak bisa dikembalikan lagi.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-round btn-default"
                    data-dismiss="modal"
                    onClick={() => {
                      setModal("");
                      emptyData();
                    }}
                  >
                    Tutup
                  </button>
                  <button
                    type="button"
                    className="btn btn-round btn-primary"
                    onClick={() => deleteData()}
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
