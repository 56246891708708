import React, { useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { NumericFormat } from "react-number-format";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.min.css";

import ReactExport from "react-export-excel-hot-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const StatusHandler = (props) => {
  switch (props.status) {
    case "Menunggu":
      return <span className="badge badge-info">Menunggu</span>;
      break;

    case "Diterima":
      return <span className="badge badge-primary">Diterima</span>;
      break;

    case "Diterima By System":
      return <span className="badge badge-primary">Diterima By System</span>;
      break;

    case "Dikerjakan":
      return <span className="badge badge-warning">Dikerjakan</span>;
      break;

    case "Ditolak":
      return <span className="badge badge-danger">Ditolak</span>;
      break;

    case "Revisi":
      return <span className="badge badge-warning">Revisi</span>;
      break;

    case "Selesai":
      return <span className="badge badge-success">Selesai</span>;
      break;

    case "Tidak Diselesaikan":
      return <span className="badge badge-danger">Tidak Diselesaikan</span>;
      break;

    case "Tidak Dikerjakan":
      return <span className="badge badge-danger">Tidak Dikerjakan</span>;
      break;

    case "Selesai By Expired Date":
      return (
        <span className="badge badge-success">Selesai By Expired Date</span>
      );
      break;

      case "Selesai By Admin":
        return (
          <span className="badge badge-success">Selesai By Admin</span>
        );
        break;

    default:
      return <span>No Status</span>;
      break;
  }
};

const JenisHandler = (props) => {
  switch (props.jenis) {
    case "Tugas Utama":
      return <span className="badge badge-success">Tugas Utama/RBB</span>;
      break;

    case "Tugas Tambahan":
      return <span className="badge badge-primary">Tugas Khusus/Tambahan</span>;
      break;

    case "Tugas Rutin":
      return <span className="badge badge-info">Tugas Rutin Harian</span>;
      break;

    case "Tugas Event":
      return <span className="badge badge-warning">Tugas Event</span>;
      break;

    default:
      return <span>No Jenis</span>;
      break;
  }
};

function FormatDate({ dateString }) {
  const formattedDate = moment(dateString).format("D MMMM YYYY");
  if (formattedDate === "Invalid date") {
    return "-";
  } else {
    return formattedDate;
  }
}

function DateFormatter(date) {
  const formattedDate = moment(date).format("D MMMM YYYY");
  if (formattedDate === "Invalid date") {
    return "-";
  } else {
    return formattedDate;
  }
}

function DateComparator(date1, date2) {
  date1 = _monthToNum(date1);
  date2 = _monthToNum(date2);
  // console.log(date1, date2);

  if (date1 === null && date2 === null) {
    return 0;
  }
  if (date1 === null) {
    return -1;
  }
  if (date2 === null) {
    return 1;
  }

  return date1 - date2;
}

function _monthToNum(date) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const dateArr = date.split("-");

  const year = dateArr[0];
  const month = dateArr[1];
  const day = dateArr[2];

  const result = year * 10000 + month * 100 + day * 1;
  // 2004-08-29 => 20040829
  return result;
}

const TabelWorksheet = (props) => {
  const gridRef = useRef();

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  }));

  const columnTypes = {
    dateColumn: {
      comparator: DateComparator,
      filter: "agDateColumnFilter",
      filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          if (cellValue == null) {
            return 0;
          }
          const dateParts = cellValue.split("-");
          const year = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const day = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    },
  };

  const onGridReady = (e) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
  };

  const Export = () => {
    gridRef.current.api.exportDataAsExcel();
  };

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="body taskboard b-cyan planned_task">
            <h6 className="font300 mb-3">{props.tableTitle}</h6>
            {props.tableTitle === "Tabel Report Harian" ? (
              <ExcelFile
                element={
                  <button type="button" className="btn btn-sm btn-success mb-3">
                    Export Excel
                  </button>
                }
                filename={`Report Harian ${props.tglReport}`}
              >
                <ExcelSheet data={props.tableData} name="Report Harian">
                  <ExcelColumn label="ID Tugas" value="id_tugas" />
                  <ExcelColumn label="NPP" value="npp" />
                  <ExcelColumn label="Nama Pegawai" value="nama_pegawai" />
                  <ExcelColumn
                    label="Jabatan Pegawai"
                    value="jabatan_pegawai"
                  />
                  <ExcelColumn label="Kantor" value="kantor" />
                  <ExcelColumn label="Kantor Induk" value="kantor_induk" />
                  <ExcelColumn label="Tugas" value="judul" />
                  <ExcelColumn
                    label="Jenis Tugas"
                    value={(d) =>
                      d.jenis === "Tugas Utama"
                        ? "Tugas Utama/RBB"
                        : d.jenis === "Tugas Tambahan"
                        ? "Tugas Khusus/Tambahan"
                        : d.jenis === "Tugas Rutin"
                        ? "Tugas Rutin Harian"
                        : d.jenis === "Tugas Event"
                        ? "Tugas Event"
                        : ""
                    }
                  />
                  <ExcelColumn label="Uraian" value="tugas" />
                  <ExcelColumn
                    label="Tanggal Pengajuan"
                    value={(d) => FormatDate({ dateString: d.tgl_diajukan })}
                  />
                  <ExcelColumn
                    label="Tanggal Persetujuan"
                    value={(d) => FormatDate({ dateString: d.tgl_diterima })}
                  />
                  <ExcelColumn
                    label="Pejabat yang Menyetujui"
                    value="nama_atasan"
                  />
                  <ExcelColumn
                    label="Jabatan yang Menyetujui"
                    value="jabatan_atasan"
                  />
                  <ExcelColumn
                    label="Tanggal Mulai"
                    value={(d) => FormatDate({ dateString: d.start })}
                  />
                  <ExcelColumn
                    label="Tanggal Akhir"
                    value={(d) => FormatDate({ dateString: d.end })}
                  />
                  <ExcelColumn
                    label="Tanggal Expired"
                    value={(d) => FormatDate({ dateString: d.exp })}
                  />
                  <ExcelColumn
                    label="Tanggal Disetujui"
                    value={(d) => FormatDate({ dateString: d.tgl_selesai })}
                  />
                  <ExcelColumn label="Triwulan" value="triwulan" />
                  <ExcelColumn label="Tahun" value="triwulan_tahun" />
                  <ExcelColumn label="Status" value="status" />
                  <ExcelColumn label="Score" value="nilai" />
                  <ExcelColumn label="Skill" value="skill" />
                  <ExcelColumn label="Jumlah" value="jumlah" />
                  <ExcelColumn label="Kesalahan" value="kesalahan" />
                  <ExcelColumn
                    label="Jumlah Kesalahan/Koreksi"
                    value="jumlah_kesalahan"
                  />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ""
            )}
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: 700 }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={props.tableData} // Row Data for Rows
                columnDefs={props.tableHeader} // Column Defs for Columns
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={20}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const OldTableLayout = () => {
//     <div className="table-responsive">
//         <table className="table table-hover table-custom spacing8">
//             <thead>
//                 <tr className="text-center">
//                     <th>Nama</th>
//                     <th>Jabatan</th>
//                     <th>Status</th>
//                     <th>Tugas</th>
//                     <th>Jenis</th>
//                     <th>Jumlah</th>
//                     <th>Tanggal Penugasan</th>
//                     <th>Tanggal Akhir</th>
//                     <th>Tanggal Expired</th>
//                     <th>Aksi</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {props.tableData.map((e, i) => (
//                     <tr key={i} className="text-center">
//                         <td>{e.nama_pegawai}</td>
//                         <td>{e.jabatan_pegawai}</td>
//                         <td>
//                             <StatusHandler status={e.status} />
//                         </td>
//                         <td><span>{e.judul}</span></td>
//                         <td>
//                             <JenisHandler jenis={e.jenis} />
//                         </td>
//                         {/* <td>{}</td> */}
//                         <td>{e.nominal ? e.nominal : "-"}</td>
//                         <td><span>{moment(e.start).format('D MMMM YYYY')}</span></td>
//                         <td><span>{moment(e.end).format('D MMMM YYYY')}</span></td>
//                         <td><span>{e.nominal ? "-" : moment(e.exp).format('D MMMM YYYY')}</span></td>
//                         <td>
//                             {props.tableTitle !== "Tabel Tugas Dalam Proses" && <Link className='btn btn-sm btn-info' to={`/detail-project-list/${e.id_tugas}`}>Detail</Link>}
//                             {props.tableTitle === "Tabel Tugas Dalam Proses" && <Link className='btn btn-sm btn-info' to={`/details-project-list/${e.id_tugas}`}>Detail</Link>}

//                         </td>
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
//     </div>
// }

export {
  TabelWorksheet,
  JenisHandler,
  StatusHandler,
  FormatDate,
  DateComparator,
  DateFormatter,
};
