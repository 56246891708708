import axios from 'axios';
import { getCookie } from '../helpers/CookieManager';

let headerz = null
const token = getCookie('token')
if (token) {
    headerz = {'Authorization' : `Bearer ${token}`, 'content-type': 'application/json'}
}

export const api = axios.create({
    baseURL:`${process.env.REACT_APP_API_BASE_URL}/api/v2/kpi`,
    responseType: 'json',
    headers: headerz
})

export const apiWs = axios.create({
    baseURL:`${process.env.REACT_APP_API_BASE_URL}/api/v2/worksheet`,
    responseType: 'json',
    headers: headerz
})

export const apiSKv1 = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_SAM_KREDIT}/v1/api`,
    responseType: 'json',
    headers: {
        'content-type': 'application/json'
    }
})