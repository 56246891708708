import React, { Component } from "react";
import { Link } from "react-router-dom";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from "multiselect-react-dropdown";
import { Button, FormGroup } from "react-bootstrap";
import { api, apiWs } from "../../configs/axios";
import { defaultLimit, toastMessageCall, encrypt } from "../../helpers/Utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import "../../FPOverride.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getCookie } from "../../helpers/CookieManager";
import Loader from "../Components/Loader";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

class AjukanTugas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      judul: "",
      kategori: "",
      jenis: "",
      start: "",
      end: "",
      tugas: "",
      pegawai: [],
      atasan: "",
      data_jenis: [],
      data_pegawai: [],
      data_atasan: [],
      data_judul_tugas_rutin: [],
      expired: "",
      nominal: "",
      file: "",
      isLoading: false,
      session_credential: {},
      atasanComboKey: "newAtasanByKantorInduk",
      pegawaiComboKey: "newPegawaiByKantorInduk",
      submitting: false,
      hariLibur: [],
    };
    this.fileInput = React.createRef();
  }

  handleChangeInput = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "jenis") {
          document.getElementById("form").reset();
          this.setState({
            judul: "",
            start: "",
            end: "",
            tugas: "",
            pegawai: [],
            atasan: "",
            expired: "",
            nominal: "",
            file: "",
          });
        }

        if (this.state.jenis === "Tugas Rutin") {
          const date = new Date().toJSON().slice(0, 10);
          this.setState({
            start: date,
            end: date,
            expired: date,
          });
        }
      }
    );
  };

  handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) =>
      option.value.split(";")
    );
    this.setState({
      pegawai: value,
    });
  };

  handleFileChange = (e) => {
    this.setState({ file: e.target.files });
  };

  handleExp = (start, end) => {
    if (start === '' || end === '') {
      return false
    }

    const param = {
      start: start,
      end: end,
    };

    apiWs.post("/get-expired-date", param).then((res) => {
      // console.log(res.data.expiredDate);
      this.setState({ expired: res.data.expiredDate });
    });

    // var a = new Date(start)
    // // console.log("ini tgl awal : "+start)
    // // console.log("ini tgl awal : "+a)
    // const a_ts = Math.floor(a.getTime() / 1000)
    // // console.log("unix tgl awal : "+a_ts)

    // var b = new Date(end)
    // // console.log("ini tgl akhir : "+b)
    // const b_ts = Math.floor(b.getTime() / 1000)
    // // console.log("unix tgl akhir : "+b_ts)

    // var range = b_ts - a_ts;
    // // console.log("unix range : "+range)

    // const exp_date_ts = (b_ts + range) + 86400
    // // console.log("unix exp date : "+exp_date_ts)

    // const exp_date = moment.unix(exp_date_ts).format("YYYY-MM-DD")
    // // console.log("exp date : "+exp_date)
    // this.setState({
    //     expired: exp_date
    // })
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      judul,
      jenis,
      start,
      end,
      tugas,
      pegawai,
      atasan,
      expired,
      nominal,
      file,
    } = this.state;

    if (start > end) {
      toastMessageCall(
        "Tanggal mulai tidak boleh lebih dari tanggal akhir",
        "top-center",
        "warning"
      );
      return false;
    }

    if (!judul) {
      toastMessageCall("Judul tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (jenis.length === 0) {
      toastMessageCall("Jenis tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (!start) {
      toastMessageCall(
        "Tanggal mulai tidak boleh kosong.",
        "top-center",
        "warning"
      );
      return false;
    }
    if (!end) {
      toastMessageCall(
        "Tanggal akhir tidak boleh kosong.",
        "top-center",
        "warning"
      );
      return false;
    }
    if (!tugas && jenis !== "Tugas Rutin") {
      toastMessageCall("Uraian tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (!nominal && jenis === "Tugas Rutin") {
      toastMessageCall("Jumlah tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (nominal > 9999 && jenis === "Tugas Rutin") {
      toastMessageCall("Jumlah tidak boleh lebih dari 9999.", "top-center", "warning");
      return false;
    }
    if (nominal < 1 && jenis === "Tugas Rutin") {
      toastMessageCall("Jumlah tidak boleh kurang dari 1.", "top-center", "warning");
      return false;
    }
    // if (pegawai.length === 0 && jenis !== "Tugas Rutin") {
    //     toastMessageCall('Pegawai tidak boleh kosong.', 'top-center', 'warning');
    //     return false;
    // }
    if (!atasan) {
      toastMessageCall("Atasan tidak boleh kosong.", "top-center", "warning");
      return false;
    }

    let param = {
      judul,
      jenis,
      start,
      end,
      tugas,
      pegawai: pegawai.map((eachPegawai) => [
        eachPegawai[6],
        eachPegawai[2],
        eachPegawai[3],
        eachPegawai[1],
      ]),
      atasan: [atasan[6], atasan[2], atasan[3], atasan[1]],
      exp: expired,
      nominal: parseInt(nominal),
      file,
    };

    // ADD LOGGED IN USER TO THE BEGGINING OF ARRAY PEGAWAI
    param.pegawai.unshift([
      this.state.session_credential.nama,
      this.state.session_credential.jabatan,
      this.state.session_credential.npp,
      this.state.session_credential.kantor,
    ]);

    // console.log(param);
    // return false;

    this.setState({ submitting: true })
    if (jenis === "Tugas Rutin") {
      const form = new FormData();
      form.append("judul", judul);
      form.append("jenis", jenis);
      form.append("start", start);
      form.append("end", end);
      form.append("pegawai", this.state.session_credential.nama);
      form.append("pegawai", this.state.session_credential.jabatan);
      form.append("pegawai", this.state.session_credential.npp);
      form.append("pegawai", this.state.session_credential.kantor);
      form.append("atasan", param.atasan[0]);
      form.append("atasan", param.atasan[1]);
      form.append("atasan", param.atasan[2]);
      form.append("atasan", param.atasan[3]);
      form.append("exp", expired);
      form.append("nominal", nominal);

      if (!file.length) {
        toastMessageCall(
          "File tugas tidak boleh kosong.",
          "top-center",
          "warning"
        );
        return false;
      } else {
        for (let i = 0; i < file.length; i++) {
          let berkas = file[i];
          form.append("files", berkas);
        }
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      apiWs
        .post("/pengajuan-tugas-rutin", form, config)
        .then((res) => {
          toastMessageCall("Berhasil ajukan tugas", "top-center", "success");
          window.location = "/tugas/dalam-proses";
        })
        .catch((err) => {
          // console.log(err)
          toastMessageCall("Gagal ajukan tugas", "top-center", "error");
          return false;
        })
        .finally(() => {
          this.setState({ submitting: false })
        });
    } else {
      apiWs
        .post("/pengajuan-tugas", param)
        .then((res) => {
          toastMessageCall("Berhasil ajukan tugas", "top-center", "success");
          window.location = "/tugas/pengajuan";
        })
        .catch((err) => {
          // console.log(err)
          toastMessageCall("Gagal ajukan tugas", "top-center", "error");
          return false;
        })
        .finally(() => {
          this.setState({ submitting: false })
        });
    }
  };

  _loading(status) {
    this.setState({
      isLoading: status
    });
  }

  refreshDataPegawai() {
    const session_credential = {
      id_pegawai: this.state.session_credential.id_pegawai,
      npp: this.state.session_credential.npp,
      nama: this.state.session_credential.nama,
      jabatan: this.state.session_credential.jabatan,
      kantor: this.state.session_credential.kantor,
      role: this.state.session_credential.role,
    };

    // LOAD PEGAWAI
    var param = {
      key:
        this.state.pegawaiComboKey +
        "$" +
        session_credential.id_pegawai +
        "$" +
        session_credential.npp +
        "$" +
        session_credential.nama +
        "$" +
        session_credential.jabatan +
        "$" +
        session_credential.kantor +
        "$" +
        session_credential.role,
    };

    api
      .get("/pegawai/data/combo", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item, i, row) => {
            // console.log(item);
            if (
              item[5] !== this.state.session_credential.id_pegawai &&
              item[3] !== this.state.atasan[3]
            ) {
              ar.push(item);
            }
          });
        }

        ar.sort(function (x, y) {
          return x[6] < y[6] ? -1 : 1;
        });
        this.setState({ data_pegawai: ar });
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      });
  }

  getData() {
    this._loading(true);
    apiWs.get("/jenis-tugas").then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        this.setState({
          data_jenis: ar,
        });
      }
    });

    apiWs.get("/judul-tugas-rutin").then((res) => {
      if (res.data.result) {
        this.setState({
          data_judul_tugas_rutin: res.data.data,
        });
      }
    });

    const session_credential = {
      id_pegawai: this.state.session_credential.id_pegawai,
      npp: this.state.session_credential.npp,
      nama: this.state.session_credential.nama,
      jabatan: this.state.session_credential.jabatan,
      kantor: this.state.session_credential.kantor,
      role: this.state.session_credential.role,
    };

    // LOAD PEGAWAI
    var param = {
      key:
        this.state.pegawaiComboKey +
        "$" +
        session_credential.id_pegawai +
        "$" +
        session_credential.npp +
        "$" +
        session_credential.nama +
        "$" +
        session_credential.jabatan +
        "$" +
        session_credential.kantor +
        "$" +
        session_credential.role,
    };

    api
      .get("/pegawai/data/combo", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item, i, row) => {
            if (item[5] !== this.state.session_credential.id_pegawai) {
              ar.push(item);
            }
          });
        }

        ar.sort(function (x, y) {
          return x[6] < y[6] ? -1 : 1;
        });
        this.setState({ data_pegawai: ar });
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      });

    // LOAD ATASAN
    var param = {
      key:
        this.state.atasanComboKey +
        "$" +
        session_credential.id_pegawai +
        "$" +
        session_credential.npp +
        "$" +
        session_credential.nama +
        "$" +
        session_credential.jabatan +
        "$" +
        session_credential.kantor +
        "$" +
        session_credential.role,
    };

    api
      .get("/pegawai/data/combo", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item, i, row) => {
            if (item[5] !== this.state.session_credential.id_pegawai) {
              ar.push(item);
            }
          });
        }

        ar.sort(function (x, y) {
          return x[6] < y[6] ? -1 : 1;
        });
        this.setState({ data_atasan: ar });
        this._loading(false);
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      });

    apiWs
      .get("/hari-libur")
      .then(res => {
        // console.log(res);
        let hariLiburList = []
        res.data.data.map((v, i) => hariLiburList.push(v[0]))
        this.setState({ hariLibur: hariLiburList })
      })
      .catch(error => {
        console.log(error);
      })
  }

  componentDidMount() {
    // const token = window.sessionStorage.getItem("token");
    const token = getCookie('token')
    const tokenBody = token.split(".")[1];
    const result = JSON.parse(atob(tokenBody));
    this.setState(
      {
        session_credential: result.sub,
      },
      () => {
        this.getData();
      }
    );
  }

  render() {
    const files = this.state.file ? [...this.state.file] : [];
    return (
      <>
        {this.state.isLoading ? <Loader /> : (
          <div className="animated fadeIn">
            <ToastContainer />
            <div className="container-fluid">
              <div className="block-header">
                <div className="row clearfix">
                  <div className="col-md-6 col-sm-12">
                    <h2>Ajukan Penugasan</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Worksheet</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/">Job Desk</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Ajukan Penugasan
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <form id="form" autoComplete="off">
              <div className="row clearfix">
                <div className="col-12">
                  <div className="card">
                    <div className="body">
                      <div className="row">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Jenis Tugas
                            </label>
                          </div>
                          <select
                            className="custom-select"
                            id="jenis"
                            name="jenis"
                            value={this.state.jenis}
                            onChange={(e) => this.handleChangeInput(e)}
                          >
                            <option value="">--Silahkan Pilih Jenis Tugas--</option>
                            {this.state.data_jenis
                              .filter((e) => e.jenis_tugas !== "Tugas Event")
                              .map((d, i) => (
                                <option key={i} value={d.jenis_tugas}>
                                  {d.jenis_tugas === "Tugas Utama"
                                    ? "Tugas Utama/RBB"
                                    : d.jenis_tugas === "Tugas Tambahan"
                                      ? "Tugas Khusus/Tambahan"
                                      : d.jenis_tugas === "Tugas Rutin"
                                        ? "Tugas Rutin Harian"
                                        : "Bukan Tugas"}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              style={{ width: "150px" }}
                            >
                              Judul Tugas
                            </span>
                          </div>
                          {this.state.jenis === "Tugas Rutin" ? (
                            <select
                              className="custom-select"
                              id="inputGroupSelect01"
                              name="judul"
                              onChange={(e) => this.handleChangeInput(e)}
                            >
                              <option value="">
                                --Silahkan Pilih Jenis Tugas--
                              </option>
                              {this.state.data_judul_tugas_rutin.map((data, id) => (
                                <option key={id} value={data[1]}>
                                  {data[1]}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              type="text"
                              name="judul"
                              className="form-control"
                              placeholder="Judul Tugas"
                              onChange={(e) => this.handleChangeInput(e)}
                            />
                          )}
                        </div>
                        <div className="input-group mb-3">
                          {/* <div className="input-group-prepend">
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect01"
                          style={{ width: "150px" }}
                        >
                          Atasan
                        </label>
                      </div>
                      <select
                        className="custom-select"
                        id="inputGroupSelect01"
                        name="atasan"
                        onChange={(e) => this.handleChangeInput(e)}
                      >
                        <option defaultValue>--Silahkan Pilih Atasan--</option>
                        {this.state.data_atasan.map((atasan, index) => {
                          return (
                            <option
                              key={index}
                              value={`${atasan[6]};${atasan[2]};${atasan[3]};${atasan[1]}`}
                            >{`${atasan[6]} (${atasan[2]} - ${atasan[1]})`}</option>
                          );
                        })}
                      </select> */}
                          {/* <ThemeProvider theme={darkTheme}> */}
                          <Autocomplete
                            sx={{ width: "100%" }}
                            options={this.state.data_atasan}
                            value={this.state.atasan || null}
                            onChange={(event, newValue) =>
                              this.setState({ atasan: newValue }, () => {
                                this.refreshDataPegawai(this.state.status);
                              })
                            }
                            getOptionLabel={(option) =>
                              `${option[6]} (${option[2]} - ${option[1]})`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Atasan"
                                placeholder="Pilih Atasan"
                              />
                            )}
                          />
                          {/* </ThemeProvider> */}
                        </div>
                        {this.state.jenis !== "Tugas Rutin" ? (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupSelect01"
                                style={{ width: "150px" }}
                              >
                                Tgl Mulai
                              </label>
                            </div>
                            <Flatpickr
                              // className='form-controll'
                              options={{
                                disable: [...this.state.hariLibur,
                                function (date) {
                                  // return true to disable
                                  return (
                                    date.getDay() === 0 || date.getDay() === 6
                                  );
                                },
                                ],
                                locale: {
                                  firstDayOfWeek: 1, // start week on Monday
                                },
                                minDate: "today",
                                altInput: true,
                                altFormat: "j F Y",
                                dateFormat: "Y-m-d",
                                onChange: (selectedDates, dateStr, instance) => {
                                  this.setState(
                                    {
                                      start: dateStr,
                                    },
                                    () => {
                                      this.handleExp(
                                        this.state.start,
                                        this.state.end
                                      );
                                    }
                                  );
                                },
                              }}
                            />
                          </div>
                        ) : (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                style={{ width: "150px" }}
                              >
                                Tanggal
                              </span>
                            </div>
                            <span className="ml-3 my-auto">
                              {moment(this.state.start).format("D MMMM YYYY")}
                            </span>
                          </div>
                        )}
                        {this.state.jenis !== "Tugas Rutin" && (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupSelect01"
                                style={{ width: "150px" }}
                              >
                                Tgl Akhir
                              </label>
                            </div>
                            <Flatpickr
                              // className='form-controll'
                              options={{
                                disable: [...this.state.hariLibur,
                                function (date) {
                                  // return true to disable
                                  return (
                                    date.getDay() === 0 || date.getDay() === 6
                                  );
                                },
                                ],
                                locale: {
                                  firstDayOfWeek: 1, // start week on Monday
                                },
                                minDate: "today",
                                altInput: true,
                                altFormat: "j F Y",
                                dateFormat: "Y-m-d",
                                onChange: (selectedDates, dateStr, instance) => {
                                  this.setState(
                                    {
                                      end: dateStr,
                                    },
                                    () => {
                                      this.handleExp(
                                        this.state.start,
                                        this.state.end
                                      );
                                    }
                                  );
                                },
                              }}
                            />
                          </div>
                        )}
                        {this.state.jenis !== "Tugas Rutin" && (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                style={{ width: "150px" }}
                              >
                                Tgl Expired
                              </span>
                            </div>
                            <span className="ml-3 my-auto">
                              {this.state.expired !== ""
                                ? moment(this.state.expired).format("D MMMM YYYY")
                                : ""}
                            </span>
                          </div>
                        )}
                        {this.state.jenis !== "Tugas Rutin" ? (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupSelect01"
                                style={{ width: "150px" }}
                              >
                                Uraian Tugas
                              </label>
                            </div>
                            <textarea
                              className="form-control"
                              name="tugas"
                              value={this.state.tugas}
                              onChange={(e) => this.handleChangeInput(e)}
                              rows={7}
                              placeholder="Uraian Tugas"
                            />
                          </div>
                        ) : (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupSelect01"
                                style={{ width: "150px" }}
                              >
                                Jumlah
                              </label>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              name="nominal"
                              value={this.state.nominal}
                              onChange={(e) => this.handleChangeInput(e)}
                              placeholder="Input Angka Jumlah Transaksi/Pembukaan Rekening/Pendaftaran"
                              aria-label="Uraian"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        )}
                        {this.state.jenis !== "Tugas Rutin" && (
                          <div className="input-group mb-3">
                            {/* <ThemeProvider theme={darkTheme}> */}
                            <Autocomplete
                              multiple
                              sx={{ width: "100%" }}
                              options={this.state.data_pegawai}
                              value={this.state.pegawai}
                              onChange={(event, newValue) =>
                                this.setState({ pegawai: newValue })
                              }
                              getOptionLabel={(option) =>
                                `${option[6]} (${option[2]} - ${option[1]})`
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Pilih Rekan Pegawai Untuk Penyelesaian Tugas"
                                  placeholder="Pilih Rekan Pegawai Untuk Penyelesaian Tugas"
                                />
                              )}
                            />
                            {/* </ThemeProvider> */}
                          </div>
                        )}
                        {this.state.jenis === "Tugas Rutin" && (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                style={{ width: "150px" }}
                              >
                                Input File
                              </span>
                            </div>
                            <input
                              type="file"
                              name="file"
                              ref={this.fileInput}
                              className="ml-3 my-auto"
                              multiple
                              onChange={(e) => this.handleFileChange(e)}
                            />
                            <br />
                            <ul>
                              {files.map((file, i) => (
                                <li key={i}>{file.name}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className="col-md-12 col-sm-12 text-right">
                          <Button
                            className="btn btn-sm btn-secondary"
                            onClick={() => {
                              window.history.go(-1);
                            }}
                          >
                            Batal
                          </Button>{" "}
                          <Button
                            className="btn btn-sm btn-primary"
                            color="primary"
                            type="button"
                            onClick={(e) => this.handleSubmit(e)}
                            disabled={this.state.submitting}
                          >
                            Simpan
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </>
    );
  }
}
export default AjukanTugas;
