import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleSearchBar } from '../../actions/settingsAction';

const Searchbar = ({ isSearchbar, toggleSearchBar }) => {
    return (
        <>
            <div className={`search_div${isSearchbar ? " open" : ""}`}>
                <div className="card">
                    <div className="body">
                        <form id="navbar-search" className="navbar-form search-form">
                            <div className="input-group mb-0">
                                <input type="text" className="form-control" placeholder="Nama Pegawai..." />
                                <div className="input-group-append">
                                    <span className="input-group-text"><i className="icon-magnifier"></i></span>
                                    <span onClick={() => toggleSearchBar(false)} className="search_toggle btn btn-danger"><i className="icon-close"></i></span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <span>Hasil Pencarian <small className="float-right text-muted">Jumlah 4 Orang</small></span>
                <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                        <tbody>
                            <tr>
                                <td className="w40">
                                    <span>1</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                    <img src="../assets/images/xs/user.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                        <div className="ml-3">
                                            <Link to="#" title="">Pegawai 1</Link>
                                            <p className="mb-0">[Jabatan]</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>2</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src="../assets/images/xs/user.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                        <div className="ml-3">
                                            <a href="/" title="">Pegawai 2</a>
                                            <p className="mb-0">[Jabatan]</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>3</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                    <img src="../assets/images/xs/user.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                        <div className="ml-3">
                                            <a href="/" title="">Pegawai 3</a>
                                            <p className="mb-0">[Jabatan]</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>4</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                    <img src="../assets/images/xs/user.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                        <div className="ml-3">
                                            <a href="/" title="">Pegawai 4</a>
                                            <p className="mb-0">[Jabatan]</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = state => ({
    isSearchbar: state.settings.isSearchbar
})

const mapDispatchToProps = dispatch => ({
    toggleSearchBar: (e) => dispatch(toggleSearchBar(e))
})
export default connect(mapStateToProps, mapDispatchToProps)(Searchbar);