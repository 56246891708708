import React from "react"
import { toastMessageCall } from "../../helpers/Utils";
import { apiWs } from '../../configs/axios';

export function BtnSelesaiByAdmin({ idTugas, successAction }) {
    const [modalShow, setModalShow] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [keterangan, setKeterangan] = React.useState("")

    const selesaikanTugasByAdmin = React.useCallback(async ({ id_tugas, keterangan }) => {
        setIsLoading(true);
        const data = { id_tugas, keterangan }
        try {
            await apiWs.post('/selesai-by-admin', data)
            toastMessageCall("Berhasil Menyelesaikan Tugas!", "top-center", "success");
            successAction();
        } catch (error) {
            toastMessageCall(`${error}`, "top-center", "error");
            console.error(error);
        } finally {
            setIsLoading(false);
            setModalShow(false);
        }
    }, [successAction]);

    React.useEffect(() => {
        if (!modalShow) {
            setKeterangan('')
        }
    }, [modalShow])

    return (
        <>
            <button
                className="btn btn-success btn-sm"
                onClick={() => setModalShow(true)}
            >
                Selesai By Admin
            </button>

            <div className={`modal fade  ${modalShow ? 'd-block show' : ''}`}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Selesaikan Tugas Ini?</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setModalShow(false)}
                                disabled={isLoading}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1em",
                            }}>
                                <span>
                                    Ubah status tugas ini menjadi "Selesai By Admin"? Perubahan status ini akan mengubah nilai tugas menjadi 3 dan seluruh kesalahan tugas akan dihilangkan.
                                </span>
                                <textarea
                                    disabled={isLoading}
                                    placeholder="Keterangan"
                                    rows={3}
                                    value={keterangan}
                                    onChange={(e) => setKeterangan(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-round btn-success"
                                disabled={isLoading}
                                onClick={() => selesaikanTugasByAdmin({
                                    id_tugas: idTugas,
                                    keterangan,
                                })}
                            >
                                {isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Selesaikan'}
                            </button>
                            <button
                                type="button"
                                disabled={isLoading}
                                className="btn btn-round btn-danger"
                                onClick={() => setModalShow(false)}
                            >
                                Tutup
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}