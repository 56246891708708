import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { api } from '../../configs/axios';
import {
    tableBodyDireksiAktif,
    tableBodyDireksiTidakAktif
} from '../Table/tableDireksi';

import {
    defaultLimit,
    toastMessageCall,
} from '../../helpers/Utils';

import { comboJabatan } from '../Combo/ComboJabatan';

import 'react-toastify/dist/ReactToastify.css';

const Direksi = () => {
    const [dataTableDireksiAktif, setDataTableDireksiAktif] = useState([]);
    const [dataTableDireksiTidakAktif, setDataTableDireksiTidakAktif] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModal, setModal] = useState('');

    /** combo  **/
    const [dataComboJabatan, setDataComboJabatan] = useState([]);

    /** variable */
    const [xId, setxId] = useState('');
    const [xJabatan, setxJabatan] = useState('');
    const [xNama, setxNama] = useState('');
    const [xNpp, setxNpp] = useState('');
    const [xRowNo, setxRowNo] = useState('');
    const [key, setKey] = useState('');

    const kosongBox = () => {
        setKey('');
        setxId('');
        setxJabatan('');
        setxNama('');
        setxNpp('');
    };

    const loadDataComboJabatan = () => {
        var param = { key: '~$~2' };
        api.get('/jabatan', { params: param }).then(res => {
            if (res.data.result) {
                var ar = [];
                if (res.data.data.length) {
                    res.data.data.map((item) => {
                        ar.push(JSON.parse(item));
                    });
                };
                setDataComboJabatan(ar);
            }
        });
    };

    const loadData = (item) => {
        if (item) {
            setKey(item.status_aktif + "~$~" + item.npp + "~$~" + item.id_direksi);
            setxId(item.id_direksi);
            setxJabatan(item.jabatan);
            setxNama(item.nama);
            setxNpp(item.npp);
        }
    };

    const loadDataTableAktif = () => {
        setLoading(true);
        var param = { key: "True~$~~$~", limit: defaultLimit(), start_row: 0 };
        api.get('/direksi', { params: param }).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.messsage, 'top-center', 'warning');
                return false;
            };

            var ar = [];
            if (res.data.data.length) {
                res.data.data.map((item, i, row) => {
                    ar.push(JSON.parse(item));
                });
            };
            setDataTableDireksiAktif(ar);

        }).catch(err => {
            toastMessageCall('Gagal terhubungan dengan backend. Error : ' + err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const loadDataTableTidakAktif = () => {
        setLoading(true);
        var param = { key: "False~$~~$~", limit: defaultLimit(), start_row: 0 };
        api.get('/direksi', { params: param }).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.messsage, 'top-center', 'warning');
                return false;
            };

            var ar = [];
            if (res.data.data.length) {
                res.data.data.map((item, i, row) => {
                    ar.push(JSON.parse(item));
                });
            };
            setDataTableDireksiTidakAktif(ar);

        }).catch(err => {
            toastMessageCall('Gagal terhubungan dengan backend. Error : ' + err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const simpanDireksi = () => {
        if (!xNpp) {
            toastMessageCall('NPP tidak boleh kosong.', 'top-center', 'warning');
            return false;
        };

        if (!xNama) {
            toastMessageCall('Nama tidak boleh kosong.', 'top-center', 'warning');
            return false;
        };

        if (!xJabatan) {
            toastMessageCall('Jabatan tidak boleh kosong.', 'top-center', 'warning');
            return false;
        };

        setLoading(false);
        api.post('/direksi', {
            key: key,
            jabatan: xJabatan,
            nama: xNama,
            npp: xNpp
        }).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.message, 'top-center', 'warning');
                return false;
            };

            toastMessageCall(res.data.message, 'top-center', 'success');
            loadDataTableAktif();
            loadDataTableTidakAktif();
            kosongBox();
            setModal('');
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const deleteDireksi = () => {
        if (!key) {
            toastMessageCall('Belum ada data yang dipilh', 'top-center', 'warning');
            return false;
        };

        setLoading(true);
        api.delete('/direksi/' + key).then(res => {
            if (!res.data.result) {
                toastMessageCall(res.data.message, 'top-center', 'error');
                return false;
            };
            toastMessageCall(res.data.message, 'top-center', 'success');
            loadDataTableAktif();
            loadDataTableTidakAktif();
            setModal('');
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });
    };

    const setAktif = (item) => {

    };

    useEffect(() => {
        loadDataComboJabatan();
        loadDataTableAktif();
        loadDataTableTidakAktif();
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Direksi</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">KPI</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Direksi</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <button type='button' className='btn btn-sm btn-primary mr-1 mb-2' onClick={() => {
                                kosongBox();
                                setModal('modal-direksi');
                            }}>Tambah Direksi</button>
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    {tableBodyDireksiAktif(loading, dataTableDireksiAktif, { loadData }, { setModal })}
                </div>
                <div className="row clearfix">
                    <div className='col-lg-12'>
                        <h6>Direksi periode sebelumnya ( Tidak Aktif )</h6>
                        <div className='table-responsive'>
                            <table className='table table-hover table-custom spacing8'>
                                <thead>
                                    <tr>
                                        <th className='w60'>NAMA</th>
                                        <th></th>
                                        <th>NPP</th>
                                        <th>PERIODE</th>
                                        <th>TAHUN</th>
                                        <th className='w100'></th>
                                    </tr>
                                </thead>
                                <tbody>{tableBodyDireksiTidakAktif(loading, dataTableDireksiTidakAktif, { loadData }, { setModal })}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal fade ${isModal === 'modal-direksi' ? 'd-block show' : ''}`} >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Direksi</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ width: '150px' }}>NPP</span>
                                </div>
                                <input type="text" className="form-control" placeholder="" aria-label="NPP" aria-describedby="basic-addon1" value={xNpp} onChange={(e) => { setxNpp(e.target.value.toUpperCase()) }} />
                            </div>

                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ width: '150px' }}>Nama</span>
                                </div>
                                <input type="text" className="form-control" placeholder="" aria-label="Nama" aria-describedby="basic-addon1" value={xNama} onChange={(e) => { setxNama(e.target.value.toUpperCase()) }} />
                            </div>

                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" style={{ width: '150px' }}>Jabatan</span>
                                </div>
                                <select className='custom-select form-control' value={xJabatan} onChange={(e) => { setxJabatan(e.target.value) }}>
                                    {comboJabatan(dataComboJabatan)}
                                </select>
                            </div>

                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => {
                                setModal('');
                                kosongBox();
                            }}>Tutup</button>
                            <button type="button" className="btn btn-round btn-primary" onClick={() => {
                                simpanDireksi();
                            }}>Simpan</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal fade ${isModal === 'modal-hapus-direksi' ? 'd-block show' : ''}`} >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Direksi</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className='modal-body text-center pt-4 mb-4'>
                            <h5>Yakin data akan dihapus?</h5>
                            <p className="mb-4">Data yang sudah dihapus tidak bisa dikembalikan lagi.</p>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => {
                                setModal('');
                                kosongBox();
                            }}>Tutup</button>
                            <button type="button" className="btn btn-round btn-primary" onClick={() => {
                                deleteDireksi();
                            }}>Hapus</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}

export default Direksi;