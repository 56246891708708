import React from "react";
import Tooltip from '../common/toolTip';

const tableBodyPeriodeTidakAktif = (loading, data,{loadData}, {setModal}, {setAktif}) =>{
    return data && data.map((key, index)=>{
        return(
            <tr>
                <td>{key.tahun}</td>
                <td>{key.periode}</td>
                <td><span className="badge badge-danger ml-0 mr-0">Tidak Aktif</span></td>
                <td style={{textAlign:'right'}}>
                    <Tooltip text="Set Aktif" id="SetAktif" />
                    <button type="button" className="btn btn-sm btn-default" title="Set Aktif" data-for="setAktif" onClick={()=>{
                        setAktif(key);
                    }}><i className="icon-check"></i></button>
                    <Tooltip text="Edit" id="Edit" />
                    <button type="button" className="btn btn-sm btn-default" title="Edit" data-for="edit" data-tip onClick={()=>{
                        loadData(key);
                        setModal('modal-periode');
                    }}><i className="icon-pencil"></i></button>
                    <Tooltip text="Hapus" id="Hapus" />
                    <button type="button" className="btn btn-sm btn-default" title="Hapus" data-for="hapus" data-tip onClick={()=>{
                        loadData(key);
                        setModal('modal-hapus-periode');
                    }}><i className="icon-trash"></i></button>
                </td>
            </tr>
        )
    })
}

export{
    tableBodyPeriodeTidakAktif
}